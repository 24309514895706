import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { Box, Grid, Typography, Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ComisionApertura1 from "../assets/ExtraPages/comision_apertura.jpg"
import ComisionApertura2 from "../assets/ExtraPages/comision_apertura2.jpg"
import ComisionGestion from "../assets/ExtraPages/comision_gestion.jpg"
import Producto from "../assets/ExtraPages/producto.png"
import Fondos from "../assets/ExtraPages/fondos.png"
import Limite from "../assets/ExtraPages/limite.jpg"

const useStyles = makeStyles(theme => ({
  root: {},
  ol: { paddingInlineStart: 0 },
  containerPart: {
    [theme.breakpoints.down("md")]: {
      marginTop: "6em",
    },
    marginTop: "10em",
  },
  linksStyles: {
    textDecoration: "underline",
    color: theme.palette.common.black,
    fontWeight: 500,
    "&:hover": {
      color: "#291C64",
    },
  },
  imgSize: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "3em",
    },
  },
  boxImg: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
  },
  liStyle: {
    textAlign: "justify",
  },
}))

export default function TermsCond() {
  const classes = useStyles()
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CFL | Terminos del servicio</title>
      </Helmet>
      <Container className={classes.containerPart}>
        <Box maxWidth="80%" m="auto">
          <Box mt={4} py={2}>
            <Typography variant="h1" component="h1" gutterBottom>
              <b>Términos y Condiciones Generales de Uso</b>
            </Typography>
          </Box>
          <Box mb={4} py={2}>
            <Typography variant="h3" gutterBottom inline>
              <b>I. Introducción</b>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              inline
              className={classes.liStyle}
            >
              <ol>
                <li className={classes.liStyle}>
                  Emprendimientos Maussan y González, Sociedad Anónima Promotora
                  de Inversión de Capital Variable, Institución de
                  Financiamiento Colectivo, quien se identifica bajo el nombre
                  comercial CFL (en adelante, “CFL” o la “Sociedad”), está
                  debidamente constituida de conformidad con las leyes de los
                  Estados Unidos Mexicanos (“México”). El pasado 6 de abril de
                  2023, salió publicada en el Diario Oficial de la Federación la
                  autorización a CFL para operar y funcionar como una
                  Institución de Financiamiento Colectivo en los términos de la
                  Ley para Regular las Instituciones de Tecnología Financiera
                  promulgada y publicada en el Diario Oficial de la Federación
                  el 9 de marzo de 2018. El Registro Federal de Contribuyentes
                  de CFL es EMG160524DG8 y tiene sus oficinas registradas en
                  Calle Puebla 237, Colonia Roma Norte, Ciudad de México, C.P.
                  06700.
                  <br />
                  Las términos y condiciones que se presentan a continuación
                  (los “Términos”), rigen la operación y el uso del sitio web de
                  CFL en www.capitalfundinglab.com (el “Sitio Web”) y de la
                  plataforma de crédito operada por CFL a través del Sitio Web
                  (la “Plataforma”), así como cualquier versión móvil y
                  cualquier aplicación de dicha Plataforma, que permite a los
                  Clientes dar un crédito a, o recibir un crédito de, otros
                  Clientes en este sentido, es importante que usted haga lo
                  siguiente: Por favor, asegúrese de leer los presentes Términos
                  cuidadosamente antes de usar el Sitio Web o la Plataforma ya
                  que se encuentran incorporados en el proceso de registro y
                  apertura de la cuenta como Cliente y suponen un contrato
                  vinculante entre usted y CFL. En cualquier momento usted podrá
                  consultar estos Términos a través del Sitio Web y la
                  Plataforma.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Los Términos se tendrán por consentidos mediante el click de
                  aceptación del Cliente o cualquier otro acto de consentimiento
                  expreso que el Cliente exprese en el Sitio Web o la
                  Plataforma. Adicionalmente, al acceder o utilizar el Sitio Web
                  y/o la Plataforma por cualquier medio, el Cliente o cualquier
                  usuario acepta que ha leído y entendido estos Términos y que
                  acepta y conviene sujetarse a los mismos, sin importar si es o
                  no un Cliente registrado.
                  <br />
                  En caso de que usted sea una persona física, al aceptar estos
                  Términos usted manifiesta que actúa por cuenta propia. En caso
                  de que usted sea un representante de algún tercero, persona
                  física, al aceptar estos Términos usted manifiesta que ha
                  proporcionado, o proporcionará a CFL antes de realizar
                  cualquier operación a través de la Plataforma, la información
                  que CFL le solicite a efecto de dar cumplimiento a lo
                  establecido en el artículo 11, fracción VI de las
                  Disposiciones de carácter general a que se refiere el Artículo
                  58 de la Ley para Regular las Instituciones de Tecnología
                  Financiera.
                </li>
                <br />
                <li className={classes.liStyle}>
                  A través de nuestra Plataforma, usted podrá: (a) obtener
                  Créditos por parte de un o un conjunto de Inversionista (s),
                  si éste es una persona física, de nacionalidad mexicana o de
                  nacionalidad extranjera en condiciones de estancia de
                  residente temporal o residente permanente en términos de la
                  Ley de Migración, o si es una persona moral de nacionalidad
                  mexicana, o (b) participar como Inversionista en los créditos
                  otorgados vía nuestra Plataforma.
                </li>
                <br />
                <li className={classes.liStyle}>
                  La relación real de préstamo entre los Clientes que actúen
                  como Inversionistas (en dicho carácter, los “Inversionistas”)
                  en los créditos que se celebren a través de la Plataforma (los
                  “Créditos”), y los Clientes que actúen como Solicitantes en
                  los mismos (los “Solicitantes”) se regirá por un Contrato de
                  Crédito; sin embargo, todos los Clientes y usuarios
                  (registrados o no), estarán obligados a entender y aceptar los
                  presentes Términos.
                </li>
                <br />
                <li className={classes.liStyle}>
                  En caso de conflicto entre los presentes Términos y el
                  Contrato de Crédito, se deberá dar prioridad al Contrato de
                  Crédito.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Los Términos “nosotros”, “nos”, y “nuestros” se refieren a CFL
                  y “usted”, “su” y “suyo” se refieren a usted como Cliente (en
                  su carácter de Inversionista o Solicitante, según sea
                  aplicable) e incluso como usuario no registrado como Cliente.
                  Los términos utilizados en mayúscula inicial se definen donde
                  aparecen por primera vez o en la Sección XVIII (Términos
                  Definidos) de estos Términos.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Los encabezados se incluyen como ayuda para ubicar las
                  disposiciones relevantes, sin embargo, no influyen ni deberán
                  influir en el significado o interpretación de dichas
                  disposiciones.
                </li>
                <br />
                <li className={classes.liStyle}>
                  CFL se reserva el derecho de realizar periódicamente
                  modificaciones a los Términos y/o a la forma de operar del
                  Sitio Web o de la Plataforma conforme a lo establecido en la
                  Sección XII (Modificaciones a los Términos de CFL y a las
                  condiciones de los Créditos) de estos Términos. Si usted no
                  está de acuerdo con estos Términos o con cualquier
                  modificación, deberá dejar de utilizar el Sitio Web y la
                  Plataforma.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Para todo lo relativo a la interpretación y cumplimiento de
                  los presentes Términos, las partes de mismo se someten, de
                  manera expresa e irrevocable, a las leyes aplicables de
                  México, y a la jurisdicción de los tribunales competentes de
                  la Ciudad de México, México, con respecto a cualquier acción o
                  procedimiento entablado o que se derive en relación con los
                  presentes Términos, y en este acto renuncian, expresa e
                  irrevocablemente, a cualquier otra jurisdicción que pudiere
                  corresponderles en virtud de sus domicilios presentes o
                  futuros o por cualquier otro motivo.
                </li>
              </ol>
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>II. Usos y Restricciones al Sitio Web</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              <ol>
                <li className={classes.liStyle}>
                  Las operaciones y servicios en la Plataforma estarán
                  disponibles durante las 24 horas del día y los 7 días de la
                  semana; sin embargo, para la realización de transferencias
                  aplicarán los horarios habituales de las Entidades
                  Financieras. En caso de existir cualquier interrupción o falla
                  en el Sitio Web y/o en la Plataforma, el medio de comunicación
                  oficial entre CFL y sus Clientes será por correo electrónico.
                  Para tal efecto se considerarán válidas únicamente las
                  comunicaciones que provengan desde o hacia{" "}
                  <a href="mailto:contacto@capitalfundinglab.com">
                    contacto@capitalfundinglab.com.
                  </a>
                </li>
                <br />
                <li className={classes.liStyle}>
                  No obstante lo anterior, CFL no garantiza que el acceso a su
                  Sitio Web y/o a la Plataforma sea ininterrumpido o que el
                  Sitio Web y/o la Plataforma estén disponibles en todo momento;
                  en caso de existir cualquier interrupción en su Sitio Web y/o
                  Plataforma, CFL (i) hará todo lo posible por solucionarlo con
                  la mayor brevedad posible y (ii) enviará a los usuarios un
                  correo electrónico para hacer de su conocimiento las opciones
                  de continuidad del servicio.
                </li>
                <br />
                <li className={classes.liStyle}>
                  No tendremos responsabilidad alguna con usted si, por
                  cualquier razón (excepto a causa directa de nuestra
                  negligencia, incumplimiento de contrato, incumplimiento
                  deliberado o fraude), el Sitio Web y/o la Plataforma no están
                  disponibles en cualquier momento o durante cualquier periodo,
                  pero en caso de existir cualquier problema en o falta de
                  disponibilidad del Sitio Web y/o Plataforma, CFL (i) hará todo
                  lo posible por solucionarlo con la mayor brevedad posible y
                  (ii) enviará a los usuarios un correo electrónico para hacer
                  de su conocimiento las opciones de continuidad del servicio.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Es su responsabilidad asegurarse de tener acceso suficiente al
                  Sitio Web y/o a la Plataforma.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Usted es responsable de asegurarse que todas las personas que
                  accedan a nuestro Sitio Web a través de su conexión a Internet
                  conozcan los presentes Términos y que los cumplan.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Solamente accederá a nuestro Sitio Web y/o a la Plataforma
                  para el fin para el que cada uno está destinado y reconoce y
                  acepta que está prohibido el uso del Sitio Web y/o de la
                  Plataforma, y/o sus respectivos contenidos, para cargar o
                  transmitir virus o cualquier otro tipo de contenido malicioso.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Usted no intentará descompilar o realizar ingeniería inversa o
                  copiar ningún aspecto de nuestro Sitio Web o tecnología de
                  nuestra Plataforma.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Llevamos a cabo esfuerzos razonables para proteger el Sitio
                  Web, la Plataforma y nuestros servicios pero no podemos
                  garantizar que el Sitio Web sea seguro o esté libre de
                  cualquier virus o material informático dañino.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Usted es responsable de configurar su tecnología de la
                  información, programas informáticos y plataforma para acceder
                  al Sitio Web y a la Plataforma y deberá utilizar su propio
                  software de protección antivirus.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Usted comprende que no podrá crear cuentas o utilizar el Sitio
                  Web proporcionando datos falsos, incompletos o equívocos en
                  medida o grado que induzcan al error. En caso de crear cuentas
                  o utilizar el Sitio Web utilizando datos falsos, Usted será
                  completamente responsable de todas las consecuencias legales,
                  obligándose a dejar en paz y a salvo todos los derechos e
                  intereses de CFL.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Usted acepta que no tiene derecho de colocar o utilizar los
                  productos y servicios del Sitio Web en páginas propias o
                  sitios de terceros sin autorización previa de CFL.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Usted no podrá limitar o impedir a cualquier usuario el uso
                  del Sitio Web.
                </li>
              </ol>
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>III. Acerca de CFL</b>
            </Typography>
            <Typography variant="h4" gutterBottom inline>
              <b>1. Actividades que realiza CFL</b>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              inline
              className={classes.liStyle}
            >
              A través de nuestra Plataforma facilitamos el otorgamiento de
              Créditos por Inversionistas a personas físicas que declaren ser de
              nacionalidad mexicana o de nacionalidad extranjera en condiciones
              de estancia de residente temporal o residente permanente en
              términos de la Ley de Migración, y a personas morales de
              nacionalidad mexicana. Cada Crédito podrá ser otorgado por un solo
              Inversionista a un Acreditado o podrá comprender varias Partes del
              Crédito otorgadas por distintos Inversionistas cada una, de
              acuerdo con los límites de diversificación que deben ser aplicados
              a cada Inversionista conforme a lo establecido en las
              Disposiciones de Carácter General Aplicables a las Instituciones
              de Tecnología Financiera y que se describen de manera general en
              la Sección VII (Otorgamiento de Créditos a través de CFL),
              apartado 2, párrafo D de estos Términos, en cuyo caso, el
              otorgamiento de cada Parte del Crédito constituye una relación
              contractual separada entre cada Inversionista y el Solicitante
              correspondiente, bajo los términos del Contrato de Crédito
              respectivo. La Plataforma determinará qué Partes del Crédito se
              pondrán a disposición de qué Inversionistas conforme a lo descrito
              a continuación. CFL, en nombre y para beneficio de los
              Inversionistas, llevará a cabo esfuerzos razonables para:
              <ol>
                <li className={classes.liStyle}>
                  Obtener de los Solicitantes y/o de terceros, información que
                  nos permita llevar a cabo una evaluación de riesgo crediticio
                  proporcional a la naturaleza del Crédito propuesto y al perfil
                  del Acreditado;
                </li>
                <li className={classes.liStyle}>
                  Determinar una tasa de interés apropiada para cada Crédito con
                  base en el monto del Crédito, su duración o vigencia y el
                  perfil de riesgo del Solicitante; y
                </li>
                <li className={classes.liStyle}>
                  Armar una cartera de Créditos para cada Inversionista de
                  conformidad con los Requisitos del Producto relevantes. CFL
                  cuenta con un sistema mediante el cual ejecuta un{" "}
                  <b>
                    algoritmo que asigna préstamos entre Inversionistas y
                    Solicitantes según las instrucciones de codificación. Las
                    instrucciones de codificación están diseñadas en base a un
                    sistema de colas en el cual los fondos de los Inversionistas
                    se ordenan y asignan por orden de llegada así como teniendo
                    en cuenta los límites de diversificación; es decir,
                  </b>{" "}
                  una vez que los fondos se han depositado en la Cuenta de
                  Cliente de un Inversionista y se ha seleccionado uno de los
                  productos, la plataforma considera que los fondos están
                  disponibles para ser asignados a un crédito. Así pues, los
                  fondos de los Inversionistas se ordenan en este momento en un
                  sistema de colas dándoles un "tiempo de espera promedio" en la
                  cual sus fondos serán asignados a los préstamos solicitados;
                  en el entendido, que mientras no se hubiere asignado un
                  préstamo o, en caso de haberse asignado no se hubiere obtenido
                  el monto requerido al término del Plazo de Solicitud de
                  Financiamiento Colectivo conforme a lo establecido en los
                  párrafos C y D del apartado 3 de la Sección VII (Otorgamiento
                  de Créditos a través de CFL) de estos Términos, los
                  Inversionistas podrán solicitar el retiro de sus fondos a
                  través de la Plataforma y CFL transferirá los montos
                  correspondientes a la Cuenta Bancaria del Inversionista dentro
                  de los 3 Días Hábiles siguientes a dicha solicitud. Es
                  importante destacar que CFL sólo invertirá automáticamente,
                  siempre con el previo consentimiento del Inversionista
                  otorgado a CFL mediante la Carta de Reinversión, los recursos
                  que provengan de pagos efectuados por los financiamientos
                  otorgados. En relación con lo anterior, se hace notar que el
                  sistema de asignación de cartera no se trata de una inversión
                  automática, sino que es un sistema que permite respetar el
                  orden de llegada de los solicitantes y cumplir con los límites
                  de diversificación, pero esta asignación no será ejecutada
                  automáticamente, se le informará al Inversionista a través de
                  la Plataforma del resultado de la asignación; es decir, se le
                  informará qué hay disponible para él teniendo en consideración
                  el momento de su inversión ((indicando el monto total
                  solicitado para cada proyecto a financiar, el monto de los
                  recursos que aportará el Inversionista y los medios a través
                  de los cuales se recibirán las inversiones correspondientes) y
                  los límites de diversificación y él tendrá que seleccionar la
                  opción invertir.
                </li>
              </ol>
              <p>Otras actividades que realiza CFL</p>
              <ol>
                <li className={classes.liStyle}>
                  Coordinar y concertar la recepción de los fondos de cada
                  Inversionista a través de un contrato de Comisión Mercantil,
                  antes de transferir al Solicitante el monto correspondiente
                  del Crédito.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Coordinar y concertar la recepción de los pagos de principal,
                  intereses y cualquier otro pago adeudado por el Solicitante y
                  la distribución de los mismos a cada Inversionista de
                  conformidad con sus derechos respectivos considerando las
                  Partes del Crédito que, en su caso, conformen el Crédito de
                  que se trate;
                </li>
                <br />
                <li className={classes.liStyle}>
                  Procurar (y/o disponer que se tomen medidas para procurar) el
                  pago del Crédito por parte del Solicitante respectivo; y
                </li>
                <br />
                <li className={classes.liStyle}>
                  Ejercer (o concertar el ejercicio de) los derechos que
                  cualquier Inversionista tenga bajo un Contrato de Crédito
                  celebrado con uno o varios Solicitantes
                </li>
                <br />
                <li className={classes.liStyle}>
                  Nosotros <b>NO</b>:
                  <ol>
                    <li className={classes.liStyle}>
                      Proporcionamos créditos por nuestra cuenta; ni
                    </li>
                    <li className={classes.liStyle}>
                      Asesoramos en materia de inversión sobre las ventajas de
                      participar en créditos entre pares (peer to peer) o de
                      participar en cualquier actividad de préstamo específica;
                      ni
                    </li>
                    <li className={classes.liStyle}>
                      Proporcionamos asesoría legal o fiscal.
                    </li>
                  </ol>
                </li>
                <br />
                <li className={classes.liStyle}>
                  Cada vez que se celebre un Contrato de Crédito, los detalles
                  del monto prestado se registrarán en la Cuenta de CFL de cada
                  Solicitante e Inversionista. En el caso de un Inversionista,
                  cuando el Crédito esté dividido en Partes del Crédito, el
                  monto registrado corresponderá a su respectiva Parte del
                  Crédito.
                </li>
                <br />
                <li className={classes.liStyle}>
                  La identidad de cada Cliente no será revelada a ningún otro
                  Cliente, excepto cuando sea necesario hacerlo como parte de un
                  juicio u otro procedimiento de ejecución para la recuperación
                  de las cantidades debidas y pagaderas en virtud de cualquier
                  Contrato de Crédito o para el cumplimiento de la Ley Aplicable
                </li>
              </ol>
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>IV. Comunicaciones y Quejas</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              <ol>
                <li className={classes.liStyle}>
                  Todas las comunicaciones entre nosotros y usted deberán ser en
                  español o en inglés, a través de la Plataforma o por correo
                  electrónico a las siguientes direcciones:
                  <ol>
                    <li className={classes.liStyle}>
                      CFL:{" "}
                      <a href="mailto:contacto@capitalfundinglab.com">
                        contacto@capitalfundinglab.com.
                      </a>
                    </li>
                    <li className={classes.liStyle}>
                      Cliente: El correo electrónico proporcionado para su
                      registro como Cliente en la plataforma.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  También puede comunicarse con nosotros a través de nuestras
                  cuentas de Facebook
                  https://www.facebook.com/capitalfundinglab, Twitter
                  https://twitter.com/Capital_FLab, Instagram
                  https://www.instagram.com/capitalfundinglab/ y/o Linkedin
                  https://www.linkedin.com/company/capital-funding-lab.
                  <a
                    className={classes.linksStyles}
                    href="https://www.facebook.com/capitalfundinglab"
                  >
                    Facebook
                  </a>
                  {/* ,{" "}
                  <a
                    className={classes.linksStyles}
                    href="https://twitter.com/Capital_FLab"
                  >
                    Twitter
                  </a>
                  ,{" "}
                  <a
                    className={classes.linksStyles}
                    href="https://www.instagram.com/capitalfundinglab/"
                  >
                    Instagram
                  </a>{" "}
                  y/o{" "}
                  <a
                    className={classes.linksStyles}
                    href="https://www.linkedin.com/company/capital-funding-lab"
                  >
                    Linkedin
                  </a> */}
                  .
                </li>
                <br />
                <li className={classes.liStyle}>
                  Si tiene cualquier queja con respecto a nuestros servicios
                  podrá enviarla mediante una solicitud por escrito dirigida al
                  titular de nuestra Unidad Especializada de Usuarios (UNE) y
                  entregada en la siguiente dirección: Calle Puebla número 237,
                  Colonia Roma Norte, C.P. 06700, Alcaldía Cuauhtémoc, Ciudad de
                  México, entre las 9:00 y las 17:00 horas de lunes a viernes, o
                  bien por correo electrónico a la siguiente dirección:
                  complaints@capitalfundinglab.com, o a través de la Plataforma
                  dentro de la sección denominada “Atención al Cliente”.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Nuestra Unidad Especializada de Usuarios considerará e
                  investigará con cuidado cada una de las quejas y tendrá un
                  plazo de 30 Días Hábiles siguientes contados a partir de la
                  fecha de recepción de la queja por escrito, para entregarle a
                  usted el dictamen correspondiente, junto con la información
                  y/o documentación considerada para la emisión de dicho
                  dictamen y un informe detallado en el que se respondan los
                  hechos contenidos en la queja o solicitud de aclaración.
                  Dentro de los siguientes 45 días naturales contados a partir
                  de la entrega del dictamen correspondiente, CFL pondrá a
                  disposición de usted, a través de la Unidad Especializada de
                  Usuarios, el expediente generado por la solicitud o queja, con
                  la integración de la información y documentación que deba
                  obrar en su poder y que se relacione directamente con la
                  solicitud o queja.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Los préstamos entre pares (peer to peer) no están cubiertos
                  por ningún tipo de esquemas de compensaciones y no tendrá
                  derecho a ningún tipo de compensación si la Plataforma llegara
                  a fallar. Los rendimientos de cualquier inversión que realice
                  a través de la Plataforma son estimados y no están asegurados.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Los recursos de los Clientes en las operaciones realizadas con
                  CFL no se encuentran garantizados por ninguna autoridad. Ni el
                  Gobierno Federal ni las entidades de la administración pública
                  paraestatal podrán responsabilizarse o garantizar los recursos
                  de los Clientes que sean utilizados en las operaciones que
                  celebren dichos Clientes con CFL o con otros Clientes a través
                  de la Plataforma, así como tampoco asumir alguna
                  responsabilidad por las obligaciones contraídas por CFL o por
                  algún Cliente frente a otro, en virtud de las operaciones que
                  celebren.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Para efectos informativos, los Clientes ante cualquier duda en
                  cuanto a los servicios de CFL podrán comunicarse de igual
                  forma a la Comisión Nacional para la Protección y Defensa de
                  los Usuarios de los Servicios Financieros de la Secretaría de
                  Hacienda y Crédito Público (CONDUSEF): Teléfono 55 53 400 999.
                  Página de Internet{" "}
                  <a
                    href="http://www.condusef.gob.mx"
                    className={classes.linksStyles}
                  >
                    http://www.condusef.gob.mx
                  </a>{" "}
                  y correo{" "}
                  <a href="mailto:asesoria@condusef.gob.mx">
                    asesoria@condusef.gob.mx.
                  </a>
                  asesoria@condusef.gob.mx.
                </li>
              </ol>
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>V. Elegibilidad del Cliente y registro</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              <ol>
                <li className={classes.liStyle}>
                  Podrá registrarse no más de una vez como Solicitante o como
                  Inversionista; es decir, una misma persona física o moral
                  podrá tener como máximo una Cuenta de Cliente como Solicitante
                  y una Cuenta de Cliente como Inversionista. Para crear y abrir
                  una cuenta como Cliente en CFL (una “Cuenta CFL”) deberá
                  cumplir y seguir cumpliendo con nuestros Criterios de
                  Elegibilidad. A través de la Plataforma, ingresando a su
                  Cuenta CFL podrá actualizar su información en cualquier
                  momento.
                </li>
                <br />
                <li className={classes.liStyle}>
                  Para ser elegible como Inversionista deberá, entre otros
                  aspectos:
                  <ol>
                    <li className={classes.liStyle}>
                      Ser una persona física residente en México o una persona
                      moral mexicana;
                    </li>
                    <li className={classes.liStyle}>
                      Contar con una cuenta de depósito de dinero abierta a su
                      nombre en alguna Entidad Financiera; y
                    </li>
                    <li className={classes.liStyle}>
                      Aprobar nuestro examen de aptitud de inversionistas y
                      firmar nuestra constancia electrónica sobre riesgos.
                    </li>
                  </ol>
                </li>
                <br />
                <li className={classes.liStyle}>
                  Para ser elegible como Solicitante deberá, entre otros
                  aspectos:
                  <ol>
                    <li className={classes.liStyle}>
                      Ser una persona física residente en México;
                    </li>
                    <li className={classes.liStyle}>
                      No ser una Persona Relacionada de CFL ni tener poder de
                      mando en CFL;
                    </li>
                    <li className={classes.liStyle}>
                      Contar con una cuenta de depósito de dinero abierta a su
                      nombre en alguna Entidad Financiera;
                    </li>
                    <li className={classes.liStyle}>
                      Que no exista ni haya existido procedimiento judicial
                      alguno en su contra;
                    </li>
                    <li className={classes.liStyle}>
                      No estar solicitando en el momento que acepta estos
                      Términos y Condiciones ningún otro tipo de financiamiento
                      en cualquier otra ITF; y
                    </li>
                    <li className={classes.liStyle}>
                      No estar solicitando el Crédito por un monto que suponga
                      que el mismo Solicitante obtenga recursos a través de
                      instituciones de financiamiento colectivo, que excedan del
                      equivalente en moneda nacional a 7,370,000 UDI’s.
                    </li>
                  </ol>
                </li>
                <br />
                <li className={classes.liStyle}>
                  Todos los Clientes que sean personas físicas deberán, entre
                  otros aspectos:
                  <ol>
                    <li className={classes.liStyle}>
                      Contar con al menos 3 años de antigüedad en su domicilio;
                    </li>
                    <li className={classes.liStyle}>
                      Residir en México y mostrar evidencia de lo anterior con
                      documentación adecuada, incluyendo sin limitar, estados de
                      cuenta bancarios, facturas o comprobantes de ingresos; y
                    </li>
                    <li className={classes.liStyle}>
                      Tener al menos 18 años de edad.
                    </li>
                  </ol>
                </li>
                <li className={classes.liStyle}>
                  Al aplicar en nombre y representación de una persona moral, el
                  aplicante confirma y declara que:
                  <ol>
                    <li className={classes.liStyle}>
                      Está debidamente autorizado para actuar en nombre y
                      representación de la persona moral que corresponda y para
                      aceptar y obligar a su representada conforme a los
                      presentes Términos;
                    </li>
                    <li className={classes.liStyle}>
                      La persona moral que representa cuenta con la capacidad
                      jurídica necesaria para celebrar contratos y con poderes
                      suficientes para delegar a CFL las facultades
                      correspondientes conforme a estos Términos, los términos
                      de los Contratos de Crédito y cualquier Contrato de
                      Comisión Mercantil que resulte aplicable, sin ningún otro
                      requisito de autorización; y
                    </li>
                    <li className={classes.liStyle}>
                      Su representada no otorga créditos al consumo en el curso
                      ordinario de su negocio o, de lo contrario, cuenta con los
                      permisos necesarios para actuar como Inversionista de
                      dichos créditos al consumo.
                    </li>
                  </ol>
                </li>
                <li className={classes.liStyle}>
                  En caso de cumplir con nuestros Criterios de Elegibilidad
                  podrá aplicar para registrarse con nosotros como Cliente. Como
                  parte del proceso podremos:
                  <ol>
                    <li className={classes.liStyle}>
                      Comprobar su identidad y hacer verificaciones de fraudes y
                      crediticias;
                    </li>
                    <li className={classes.liStyle}>
                      Validar los datos y verificar la autenticidad de los
                      documentos proporcionados por usted a CFL de manera
                      digital;
                    </li>
                    <li className={classes.liStyle}>
                      Obtener una Calificación Crediticia para usted por parte
                      de las sociedades de información crediticia de las que
                      seamos usuarios; para efectos de lo anterior, al aceptar
                      los presentes Términos usted autoriza a CFL a (a) realizar
                      investigaciones y consultas periódicas sobre su historial
                      crediticio y otras de naturaleza análoga en términos de lo
                      previsto en la Ley para Regular las Sociedades de
                      Información Crediticia; y (b) compartir y/o proporcionar
                      la información financiera, comercial, operativa y personal
                      del Cliente, así como la relativa a los Créditos y a los
                      Contratos de Crédito que celebre. CFL hace de su
                      conocimiento que la información que se recabe de usted y
                      los fines con los que será utilizada, se encuentran
                      disponibles en el Aviso de Privacidad que se encuentra a
                      su disposición a través del Sitio Web y la Plataforma; y
                    </li>
                    <li className={classes.liStyle}>
                      Realizar controles de antilavado de dinero para confirmar
                      su identidad (y si es una persona moral, la identidad de
                      sus propietarios reales) y cualquier otra información
                      necesaria para que podamos cumplir con nuestras
                      obligaciones legales y regulatorias. El incumplimiento de
                      los procedimientos de diligencia debida con respecto al
                      Cliente podría dar lugar a la suspensión o cancelación de
                      su Cuenta CFL.
                    </li>
                    <li className={classes.liStyle}>
                      En dado caso de que no complete el proceso de
                      identificación de acuerdo al párrafo inmediato anterior,
                      CFL se reserva el derecho de suspender el proceso de
                      contratación de cualquiera de las operaciones ya sea
                      pasiva (para la solicitud de un crédito) o activa (para
                      poder invertir en cualquiera de los productos que se
                      encuentren publicados en nuestra plataforma).
                    </li>
                  </ol>
                </li>
                <li className={classes.liStyle}>
                  Usted, mediante la aceptación de los presentes Términos hace
                  constar y manifiesta bajo protesta de decir verdad, que cumple
                  con nuestros criterio de elegibilidad establecido en los
                  puntos 2, 3 y 4 de la Sección V (Elegibilidad del Cliente y
                  registro), según corresponda. Del mismo modo, hace constar y
                  manifiesta bajo protesta de decir verdad, que (a) durante el
                  proceso de solicitud mantenido con la Plataforma hasta su
                  resolución (exitosa o no), no solicitará ningún otro tipo de
                  financiamiento colectivo de deuda con ninguna otra institución
                  de tecnología financiera, y (b) en caso de que la resolución
                  del proceso de solicitud mantenido con la Plataforma fuese
                  exitoso, esto no supondrá que usted, considerando de manera
                  conjunta financiamientos de otras instituciones de
                  financiamiento colectivo que ya tuviese y el que está
                  solicitando y por el cual está aceptando los presentes
                  Términos, obtenga recursos que excedan del equivalente en
                  moneda nacional a 7,370,000 UDI’s.
                </li>
                <li className={classes.liStyle}>
                  Nos reservamos el derecho de rechazar cualquier aplicación
                  para ser Solicitante o Inversionista, a nuestra entera
                  discreción.
                </li>
                <li>
                  Podremos aplicar criterios de elegibilidad adicionales para
                  ciertos tipos de Créditos.
                </li>
              </ol>
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>VI. Acceso a la Cuenta CFL</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              <ol>
                <li className={classes.liStyle}>
                  Para dar o recibir un Crédito a través de la Plataforma,
                  deberá ingresar a su Cuenta CFL a través del Sitio Web o de
                  cualquier versión móvil, aplicación u otro medio de acceso a
                  la misma que se encuentre disponible de tiempo en tiempo.
                </li>
                <li className={classes.liStyle}>
                  Siempre que se ingrese la dirección electrónica y contraseña
                  correctas, tendremos derecho de asumir (y asumiremos) que
                  usted es la persona que está dando las instrucciones y que ha
                  realizado las transacciones y que será responsable de las
                  mismas.{" "}
                  <b>
                    Por lo tanto, debe mantener en secreto sus Claves de Acceso
                    (así como otros códigos que protegen el acceso a su Cuenta
                    CFL y a su Cuenta de Cliente), y asegurarse de que no se
                    almacenen en su computadora u otro dispositivo de manera que
                    otros puedan hacerse pasar por usted. Además, para efectos
                    de claridad, si usted divulga sus Claves de Acceso a
                    cualquier persona o entidad a la que emplee, contrate,
                    designe o autorice para acceder a CFL también será
                    responsable de cualquier acceso, uso o mal uso del Sitio Web
                    y/o la Plataforma, así como por cualquier uso, mal uso o
                    divulgación de sus Claves de Acceso por parte de dicha
                    persona o entidad.
                  </b>
                </li>
                <li className={classes.liStyle}>
                  CFL tiene el derecho pero no la obligación de bloquear el
                  acceso a su Cuenta CFL en caso de que sospeche que se haya
                  accedido a la Cuenta CFL sin la debida autorización,
                  incluyendo si CFL sospecha que sus Claves de Acceso (así como
                  otros códigos que protegen el acceso a su Cuenta CFL y a su
                  Cuenta de Cliente) se han dado a conocer o podrían haberse
                  dado a conocer a un tercero, o sospeche de la ejecución de
                  transacciones ilegales, así como en otros casos establecidos
                  en la legislación vigente y a discreción de CFL, para
                  garantizar la seguridad de los servicios, la inviolabilidad,
                  la confidencialidad del Cliente y/o de otros Clientes de CFL,
                  y/o para evitar pérdidas que puedan ser infligidas sobre CFL o
                  los Clientes. Usted será notificado de manera gratuita vía
                  mensajes de texto SMS y correo electrónico, información para
                  poder evitar cualquier posible riesgo tecnológico, cibernético
                  y de fraude en los productos y servicios financieros
                  contratados con CFL, como por ejemplo en el caso de que CFL
                  detecte que se están realizando operaciones inusuales.
                </li>
                <li className={classes.liStyle}>
                  CFL no tendrá responsabilidad alguna en relación con
                  operaciones realizadas por el Cliente, aun cuando éstas sean
                  fraudulentas o accidentales o sean producto de la negligencia
                  por parte del Cliente en el cuidado de sus Claves Acceso,
                  salvo que las mismas hubieren sido después de transcurridas 72
                  horas contadas a partir de la entrega de una notificación a
                  CFL respecto de (i) el robo o extravío de las mismas y/o (ii)
                  la sospecha de que las Claves de Acceso del Cliente han sido
                  comprometidas; en el entendido, que en este último caso las
                  operaciones volverán a ser responsabilidad exclusiva del
                  Cliente a partir de que las Claves de Acceso sean cambiadas
                  por el mismo a través del procedimiento disponible para dichos
                  efectos en la Plataforma. El Cliente deberá notificar a CFL, a
                  través de la Plataforma o el correo electrónico
                  complaints@capitalfundinglab.com para el caso de robo,
                  extravío u olvido de sus Claves de Acceso o en caso de que las
                  mismas hayan sido comprometidas. Una vez que el Cliente haya
                  notificado a CFL la situación en cuestión, CFL proporcionará,
                  por correo electrónico desde la dirección
                  complaints@capitalfundinglab.com dentro de las 24
                  (veinticuatro) horas siguientes a la notificación que reciba
                  del Cliente, un número de referencia que señalará la fecha y
                  hora en que se recibió la notificación.
                </li>
                <li className={classes.liStyle}>
                  Podemos negarnos a actuar de conformidad con cualquier
                  instrucción que consideremos que:
                  <ol>
                    <li>No era clara;</li>
                    <li>No fue realizada por usted;</li>
                    <li>
                      Podría causar un incumplimiento a la Ley Aplicable; o
                    </li>
                    <li>
                      Sugiera que CFL está siendo utilizado para un propósito
                      ilegal.
                    </li>
                  </ol>
                </li>
                <li className={classes.liStyle}>
                  No debe compartir sus Claves de Acceso (ni ningún código que
                  proteja el acceso a su Cuenta CFL y a su Cuenta de Cliente)
                  con ninguna otra persona, ni escribirlas sin disfrazarlas.
                  Debe cambiar su contraseña de vez en cuando por razones de
                  seguridad.
                </li>
                <li>
                  Debe informarnos lo antes posible si cree que otra persona
                  podría conocer sus Claves de Acceso (o cualesquier otros
                  códigos que protejan el acceso a su Cuenta CFL y a su Cuenta
                  de Cliente) o pudiera utilizar el Sitio Web o la Plataforma
                  haciéndose pasar por usted. Hasta el momento en el que usted
                  nos informe lo anterior:
                  <ol>
                    <li className={classes.liStyle}>
                      será responsable de cualquier instrucción que recibamos y
                      conforme a la cual actuemos en consecuencia, incluso si no
                      fue dada por usted; y
                    </li>
                    <li className={classes.liStyle}>
                      no seremos responsables de ningún acceso no autorizado a
                      cualquier información confidencial sobre usted que se
                      encuentre disponible en CFL (o a través del Sitio Web o
                      Plataforma).
                    </li>
                  </ol>
                </li>
                <li className={classes.liStyle}>
                  Al ingresar a su Cuenta CFL con las Claves de Acceso
                  correspondiente se podrá tener acceso a:
                  <ol>
                    <li>
                      el monto (en su caso) que se encuentre Solicitante en su
                      Cuenta de Cliente;
                    </li>
                    <li>
                      el monto (en su caso) que usted:
                      <ol>
                        <li>
                          ha prestado y los detalles de el o los Créditos que ha
                          otorgado, y
                        </li>
                        <li>ha recibido en virtud de un Crédito.</li>
                      </ol>
                    </li>
                    <li className={classes.liStyle}>
                      Información acerca de sus transacciones y movimientos, así
                      como estados de cuenta y/o estados de operación;
                    </li>
                    <li className={classes.liStyle}>
                      ciertos detalles personales y de la operación de sus
                      Créditos; y
                    </li>
                    <li className={classes.liStyle}>
                      información acerca del Sitio Web y de la Plataforma.
                    </li>
                  </ol>
                </li>
                <li className={classes.liStyle}>
                  Existe un proceso de validación de sesión donde CFL verifica
                  si hay otra sesión activa o si está conectándose desde otro
                  dispositivo. Por seguridad, solamente puede tener una conexión
                  activa a la vez. Si existe más de una sesión activa se termina
                  inmediatamente la sesión más antigua de forma automática. Por
                  otra parte, la sesión expira si existe inactividad por más de
                  cinco minutos; si excede dicho tiempo tendrá que introducir su
                  usuario y contraseña nuevamente.
                </li>
                <li className={classes.liStyle}>
                  En caso de que olvide o extravíe sus Claves de Acceso, se
                  llevará a cabo el siguiente procedimiento para restablecer su
                  contraseña:
                  <ol>
                    <li className={classes.liStyle}>
                      Se hará click en la opción de restablecer contraseña en la
                      página de acceso, donde se le mostrará una página donde
                      deberá introducir su correo electrónico.
                    </li>
                    <li className={classes.liStyle}>
                      En caso de que el correo electrónico introducido coincida
                      con un correo electrónico de la base de datos, se le envía
                      un correo a esa dirección con un enlace único con tiempo
                      de caducidad, el cual le redirigirá a una página donde
                      podrá definir y luego confirmar su nueva contraseña.
                    </li>
                    <li className={classes.liStyle}>
                      Si todos los datos son válidos, el sistema encripta,
                      almacena y reemplaza el registro anterior con la nueva
                      contraseña proporcionada.
                    </li>
                    <li className={classes.liStyle}>
                      Se le enviará un correo notificando que su contraseña fue
                      cambiada exitosamente.
                    </li>
                    <li className={classes.liStyle}>
                      En caso de que no reconozca este cambio de contraseña, en
                      el correo existe una opción donde podrá cancelar el cambio
                      de contraseña.
                    </li>
                  </ol>
                </li>
              </ol>
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>VII. Otorgamiento de Créditos a través de CFL</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              <ol>
                <li>
                  <b>Acreditación de fondos en su Cuenta de Cliente</b>
                  <ol>
                    <li className={classes.liStyle}>
                      Para otorgar Créditos a través de la Plataforma, los
                      Inversionistas deben asegurarse de que se acrediten fondos
                      suficientes en su Cuenta de Cliente mediante transferencia
                      de fondos inmediatamente disponibles desde una cuenta de
                      depósito de dinero que se encuentre abierta a nombre del
                      Cliente en una Entidad Financiera (la “Cuenta Bancaria del
                      Inversionista”). Por favor, cite siempre su referencia
                      única cuando haga tales transferencias. Como parte del
                      proceso de registro como Inversionista, es posible que le
                      pidamos que nos proporcione detalles de su Cuenta
                      Bancaria. Todas las transferencias que realice con su
                      referencia única serán registradas en la Plataforma como
                      un abono a su Cuenta de Cliente.
                    </li>
                    <li className={classes.liStyle}>
                      Los fondos disponibles se mostrarán cuando se acrediten en
                      su Cuenta de Cliente.
                    </li>
                    <li className={classes.liStyle}>
                      CFL pone a disposición de los Inversionistas estados de
                      cuenta gratuitos electrónicos de manera trimestral a
                      través de la Plataforma, dentro de los 10 días naturales
                      siguientes a la fecha de corte que corresponda y el cierre
                      de la Cuenta CFL y la Cuenta de Cliente; en el entendido,
                      que en caso de cierre de la Cuenta CFL, el estado de
                      cuenta correspondiente se enviará al Cliente vía correo
                      electrónico.
                    </li>
                  </ol>
                </li>
                <li>
                  Hacer Ofertas de Crédito a través de productos de CFL
                  <ol>
                    <li className={classes.liStyle}>
                      Si un Inversionista ha otorgado su consentimiento para que
                      le sea aplicable nuestra función de Reinversión y hay
                      dinero disponible en su Cuenta de Cliente derivado de los
                      pagos que haya recibido de Créditos otorgados, tendremos
                      derecho a asumir que dicho dinero está disponible para
                      inversión conforme a dicha función y asignaremos sus
                      fondos a Créditos de conformidad con los Requisitos del
                      Producto aplicables y la ejecución de nuestro algoritmo.
                      La función de Reinversión de la Plataforma seleccionará
                      automáticamente los Créditos o Partes del Crédito que
                      cumplan con los Requisitos del Producto aplicables y los
                      presentes Términos. Es importante destacar que CFL sólo
                      invertirá automáticamente, siempre con el previo
                      consentimiento del inversionista otorgado a CFL mediante
                      la Carta de Reinversión, los recursos que provengan de
                      pagos efectuados por los financiamientos otorgados. El
                      Inversionista acepta que la adquisición de Partes del
                      Crédito que CFL haga en nombre y representación de dicho
                      Inversionista, será vinculante para él.
                    </li>
                    <li className={classes.liStyle}>
                      CFL se esforzará por asignar las Partes del Crédito
                      disponibles que cumplan con los Requisitos del Producto
                      aplicables a un Inversionista para reducir el saldo
                      líquido disponible de la Cuenta de Cliente de dicho
                      Inversionista tan pronto como sea posible. Sin embargo, al
                      hacerlo, CFL tomará en cuenta lo siguiente:{" "}
                      <ol>
                        <li className={classes.liStyle}>
                          La necesidad de equilibrar la rapidez con la que se
                          invierten los fondos y el nivel de diversificación de
                          los créditos de la cartera de un Inversionista; y
                        </li>
                        <li className={classes.liStyle}>
                          El objetivo de lograr la igualdad entre Inversionistas
                          que deseen adquirir Partes del Crédito con
                          características similares al mismo tiempo.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Las Partes del Crédito pueden ser adquiridas:
                      <ol>
                        <li>Como parte de una nueva Solicitud de Crédito; o</li>
                        <li>
                          De otro Inversionista en relación con un Crédito
                          existente.
                        </li>
                      </ol>
                    </li>
                    <li className={classes.liStyle}>
                      Para asegurar la diversificación dentro de la cartera de
                      un Inversionista, la Plataforma no le asignará una Parte
                      del Crédito a menos que su inversión haya sido
                      diversificada entre al menos tres Créditos distintos.
                      Además, no se harán inversiones en un Crédito (Deuda de
                      Préstamos Personales entre Personas) por un monto superior
                      al 7.5% si usted es persona física, y al 20% si usted es
                      persona moral, del total del saldo insoluto de las
                      inversiones efectivas y de los compromisos de inversión
                      realizados por usted previamente a través de CFL,
                      considerando dentro de estos últimos el compromiso de
                      inversión que se pretenda realizar en el Crédito
                      particular. En caso de tratarse de Deudas de Préstamos
                      Empresariales entre personas, no se harán inversiones en
                      un crédito por un monto superior al 15% si usted es
                      persona física, y al 20% si usted es persona moral, del
                      total del saldo insoluto de las inversiones efectivas y de
                      los compromisos de inversión realizados por Usted
                      previamente a través de CFL, considerando dentro de estos
                      últimos el compromiso de inversión que se pretenda
                      realizar en el Crédito particular. Lo anterior, en el
                      entendido que dichos límites no aplican en caso de que:
                      <ol>
                        <li className={classes.liStyle}>
                          el monto agregado de sus inversiones efectivas y
                          compromisos de inversión en CFL no supere el
                          equivalente en moneda nacional a 2,000 UDI’s, en cuyo
                          caso su inversión en un mismo Crédito podrá ser hasta
                          por el equivalente en moneda nacional a 667 UDI’s; o
                        </li>
                        <li className={classes.liStyle}>
                          en CFL existan 25 o menos solicitudes de
                          financiamiento publicadas, en cuyo caso su inversión
                          en un mismo Crédito no podrá exceder del máximo entre
                          5% del monto de financiamiento requerido o el
                          equivalente en moneda nacional a 167,000 UDI’s; o
                        </li>
                        <li className={classes.liStyle}>
                          CFL realice compromisos de inversión en algún Crédito,
                          en cuyo caso podrán aumentar los porcentajes
                          referidos, adicionando el porcentaje de inversión a
                          que la propia institución se esté comprometiendo en
                          dicho Crédito o el 25 %, lo que resulte menor; o
                        </li>
                        <li className={classes.liStyle}>
                          si Usted es un Inversionista Relacionado o un
                          Inversionista Experimentado conforme a lo establecido
                          en la legislación aplicable.
                        </li>
                      </ol>
                    </li>
                    <li className={classes.liStyle}>
                      No podemos garantizar la disponibilidad de Partes del
                      Crédito que cumplan con los Requisitos del Producto o el
                      requisito de diversificación. Esto puede significar que
                      permanezcan montos en la Cuenta de Cliente del
                      Inversionista sin ser invertidos. Los Inversionistas son
                      responsables de monitorear su Cuenta de Cliente para
                      detectar fondos no invertidos. No seremos responsables de
                      ninguna pérdida de interés que surja debido a la falta de
                      disponibilidad de Partes del Crédito que cumplan con los
                      Requisitos del Producto.
                    </li>
                    <li className={classes.liStyle}>
                      No obstante lo anterior, transcurridos 27 días naturales
                      sin que los fondos de un Inversionista disponibles en su
                      Cuenta de Cliente d hayan sido invertidos (ya sea por
                      falta de Ofertas en el Mercado que pudieran ser empatadas
                      con los Requisitos del Producto Relevantes o por cualquier
                      otro motivo), CFL enviará un correo al Inversionista
                      informando dicha circunstancia y notificándose que, salvo
                      instrucción en contrario girada por el Cliente respectivo
                      a CFL a través de la Plataforma o salvo que dichos fondos
                      sean invertidos dentro de los 3 días naturales siguientes,
                      CFL devolverá dichos montos al Inversionista mediante la
                      transferencia de los mismos a la Cuenta Bancaria del
                      Inversionista correspondiente, lo anterior se hará dentro
                      del término de 3 días naturales siguientes a la fecha de
                      dicho correo electrónico, siempre y cuando dichos fondos
                      no estén sujetos a algún tipo de promoción ofertada por la
                      Plataforma. De igual manera, aquellos recursos que por
                      pago de intereses el Inversionista reciba a través de la
                      Plataforma se mantendrán en su Cuenta de Cliente hasta ser
                      reinvertidos en el plazo máximo de 30 días. Antes de que
                      el “tiempo de espera promedio” (30 días) haya transcurrido
                      sin que los fondos provenientes del pago de intereses que
                      los inversionistas reciben a través de la Plataforma hayan
                      sido reinvertidos en su totalidad, los fondos no
                      invertidos serán devueltos a la Cuenta Bancaria del
                      Inversionista, para lo cual transcurridos 27 días
                      naturales sin que los fondos provenientes del pago de
                      intereses hayan sido reinvertidos, CFL enviará un correo
                      al Inversionista informando dicha circunstancia y
                      notificándose que, salvo instrucción en contrario girada
                      por el Cliente respectivo a CFL a través de la Plataforma
                      o salvo que dichos fondos sean invertidos dentro de los 3
                      días naturales siguientes, CFL devolverá dichos montos al
                      Inversionista mediante la transferencia de los mismos a la
                      Cuenta Bancaria del Inversionista dentro del término de 3
                      días naturales siguientes a la fecha de dicho correo
                      electrónico. En cualquier caso, los fondos que se
                      encuentren en custodia a la espera de ser invertidos se
                      mantendrán por CFL en cuentas de depósito a la vista
                      abiertas a nombre de CFL con alguna institución de crédito
                      para recibir depósitos de dinero, las cuales son
                      diferentes de aquellas en las que se mantienen los
                      recursos propios de la operación de CFL, o bien pueden
                      emplearse en operaciones de reporto solo con valores
                      emitidos por el Gobierno Federal o el Banco de México
                      celebradas al efecto, o bien pueden afectarse en un
                      fideicomiso de administración constituido para tal fin,
                      que únicamente lleve a cabo las referidas operaciones de
                      reporto,, conforme a lo establecido en las Políticas de
                      Separación de Cuentas.
                    </li>
                    <li className={classes.liStyle}>
                      CFL podrá pagar intereses sobre la cantidad de dinero que
                      se mantenga en su Cuenta de Cliente durante cualquier
                      periodo de inactividad, sin estar obligado a pagar
                      intereses por aquellos fondos que no hayan sido utilizados
                      en operaciones de financiamiento colectivo; en el caso de
                      que los pagase corresponden a promociones efectuadas por
                      la Sociedad. Estas promociones{" "}
                      <b>
                        son algo que CFL podría aplicar en caso de que así lo
                        viese oportuno pero en ningún caso se trata de algo que
                        asegure o garantice.
                      </b>
                    </li>
                    <li className={classes.liStyle}>
                      Para efectos de habilitar nuestra función de Reinversión,
                      de conformidad con lo previsto al respecto en las
                      Disposiciones de carácter general aplicables a las
                      Instituciones de Tecnología Financiera, el Cliente
                      correspondiente deberá suscribir la Carta de Reinversión
                      disponible en el registro del Cliente. El Inversionista
                      puede desactivar la función de Reinversión en cualquier
                      momento enviando un correo electrónico a
                      info@capitalfundinglab.com y solicitando que la función
                      sea desactivada.
                    </li>
                  </ol>
                </li>
                <li>
                  Comisión Mercantil de los Inversionistas
                  <ol>
                    <li className={classes.liStyle}>
                      Una vez aceptados estos Términos, a efecto de poder
                      realizar inversiones conforme a lo aquí previsto, cada
                      Cliente que actúe o pretenda actuar como Inversionista
                      deberá celebrar un Contrato de Comisión Mercantil, a
                      efecto de nombrar y autorizar a CFL para que, entre otras
                      cosas, en su representación tome las medidas y ejerza las
                      facultades que se le confieren conforme a estos Términos.
                      Para efectos de lo anterior, mediante la celebración de
                      dicho Contrato de Comisión Mercantil, cada Cliente que
                      actúe o pretenda actuar como Inversionista designará a CFL
                      como comisionista en términos de los Artículos 273, 274 y
                      285 del Código de Comercio, transcritos más adelante para,
                      entre otras cosas:
                      <ol>
                        <li className={classes.liStyle}>
                          Suscribir, otorgar, aceptar o ejecutar en su nombre y
                          representación, como Inversionista, Contratos de
                          Crédito y cualesquier Documentos del Crédito
                          relacionados, así como cualquier otro documento,
                          convenio o instrumento que sea necesario o conveniente
                          para la entrega, perfeccionamiento, suscripción,
                          adhesión o ejecución de los Contratos de Crédito y
                          Documentos del Crédito relacionados conforme a lo
                          establecido en estos Términos, incluyendo sin limitar
                          Convenios de Cesión y Adhesión conforme a los cuales
                          el Inversionista adquiera Partes del Crédito de
                          Contratos de Crédito existentes; en el entendido, que
                          CFL únicamente podrá celebrar dichos Contratos de
                          Crédito y Documentos del Crédito (incluyendo
                          cualesquier Convenios de Cesión y Adhesión)
                          relacionados una vez que el Inversionista confirme a
                          través de la Plataforma su decisión de invertir en los
                          Créditos correspondientes que le hayan sido asignados
                          a partir de la ejecución del algoritmo de CFL;
                        </li>
                        <li className={classes.liStyle}>
                          Recibir, aceptar y mantener, en nombre y para
                          beneficio del Inversionista, cualquier garantía
                          otorgada por los Solicitantes en relación con sus
                          respectivos Contratos de Crédito y demás Documentos
                          del Crédito, así como aceptar su designación como
                          beneficiario preferente respecto de las pólizas de
                          seguro contratadas y mantenidas por los Solicitantes
                          conforme a lo establecido en los Contratos de Crédito
                          y, como tal recibir, en nombre y para beneficio del
                          Inversionista, cualesquier pagos e indemnizaciones
                          derivadas de tales pólizas de seguro;
                        </li>
                        <li className={classes.liStyle}>
                          Respecto de cada Contrato de Crédito del que el
                          Inversionista sea parte, realizar la disposición del
                          Crédito conforme a lo previsto en el Contrato de
                          Crédito de que se trate y transferir, por cuenta y
                          orden del Inversionista, el monto del Crédito o de la
                          Parte del Crédito respectiva, según sea el caso, desde
                          la Cuenta de Cliente del Comitente a la Cuenta de
                          Cliente del Solicitante respectivo y posteriormente a
                          la Cuenta Bancaria del Solicitante;
                        </li>
                        <li className={classes.liStyle}>
                          Suscribir y celebrar, en su nombre y representación,
                          convenios modificatorios a los Contratos de Crédito y
                          demás Documentos del Crédito relacionados, así como a
                          cualesquier otros documentos, convenios o instrumentos
                          que sean necesarios o convenientes para la entrega,
                          perfeccionamiento, suscripción o ejecución de los
                          mismos; en el entendido, que para dichos efectos se
                          requerirá del consentimiento previo del Inversionista,
                          salvo que sean modificaciones a las condiciones
                          originales para reestructurar o renovar los Créditos,
                          hacer quitas o recibir en pago bienes, realizar
                          operaciones de factoraje, venta o cesión de derechos
                          de cobro que tengan los Inversionistas, durante la
                          recuperación extrajudicial, en cuyo caso estarán
                          sujetas a lo descrito en el párrafo viii siguiente;
                        </li>
                        <li className={classes.liStyle}>
                          En caso de que el Inversionista solicite a través de
                          la Plataforma y de conformidad con los términos y
                          condiciones de la misma el retiro de sus fondos y/o la
                          cesión y transmisión, total o parcial, de sus derechos
                          y obligaciones bajo algún Contrato de Crédito,
                          celebrar en nombre y representación del Inversionista
                          el Convenio de Cesión y Adhesión respectivo dentro de
                          los 10 Días Hábiles siguientes a aquél en que el
                          sistema automático de venta secundaria identifique a
                          un posible “Inversionista” o “Inversionista”
                          adquirente conforme al proceso previsto para tal
                          efecto en la Plataforma y, al momento de celebración
                          de dicho convenio de cesión, recibir por cuenta y
                          orden del Inversionista el monto de la
                          contraprestación pactada por la cesión desde la Cuenta
                          de Cliente del adquirente respecto a la Cuenta de
                          Cliente del Comitente y posteriormente a la cuenta
                          bancaria del Comitente;
                        </li>
                        <li className={classes.liStyle}>
                          Recibir por cuenta y orden del Inversionista todos y
                          cualesquier pagos derivados de o relacionados con el o
                          los Créditos que otorgue dicho Inversionista, los
                          Contratos de Crédito correspondientes y/o cualquier
                          otro Documento del Crédito, así como transferir por
                          cuenta y orden del Inversionista, al momento de
                          celebración de algún Convenio de Cesión y Adhesión en
                          el que el Inversionista sea adquirente, el monto de la
                          contraprestación pactada por la cesión desde la Cuenta
                          de Cliente del Comitente a la Cuenta de Cliente del
                          adquirente respectivo y posteriormente a la cuenta
                          bancaria del adquirente de que se trate;
                        </li>
                        <li className={classes.liStyle}>
                          Recibir todas y cualesquier notificaciones y avisos
                          dirigidos al Inversionista derivados de o relacionados
                          con los Contratos de Crédito y/o cualesquier
                          Documentos del Crédito relacionados;
                        </li>
                        <li className={classes.liStyle}>
                          Llevar a cabo la cobranza de los Créditos, ya sea
                          judicial o extrajudicialmente, y renegociar los
                          términos y condiciones de los Contratos de Crédito y
                          Documentos del Crédito relacionados, previa
                          instrucción al efecto por parte del Inversionista; en
                          el entendido que en la realización de la cobranza
                          extrajudicial de los derechos de cobro, así como en la
                          renegociación de los términos y condiciones de los
                          Créditos, CFL deberá informar al Inversionista las
                          acciones que llevará a cabo, por sí o a través de
                          terceros, para realizar la cobranza extrajudicial, así
                          como los alcances y objetivos que se pretenden con
                          dicha gestión, incluyendo la resolución de
                          controversias por la vía conciliatoria o de arbitraje;
                          y en el entendido, además, que los términos y
                          condiciones en los que CFL podrá pactar con los
                          Solicitantes las modificaciones a las condiciones
                          originales para reestructurar o renovar los créditos,
                          hacer quitas o recibir en pago bienes, realizar
                          operaciones de factoraje, venta o cesión de derechos
                          de cobro que tengan los Inversionistas, durante la
                          recuperación extrajudicial, así como los criterios
                          para considerar un crédito como incobrable, son las
                          que se describen más adelante
                        </li>
                        <li className={classes.liStyle}>
                          Actuar como intermediario en la venta y/o compra de
                          Partes del Crédito; y
                        </li>
                        <li className={classes.liStyle}>
                          Firmar la documentación (ya sea pública o privada) que
                          CFL considere necesaria o prudente para dar efecto a
                          lo anterior.
                        </li>
                      </ol>
                    </li>
                    <li className={classes.liStyle}>
                      CFL, en el ejercicio de las comisiones otorgadas conforme
                      al referido Contrato de Comisión Mercantil, deberá:
                      <ol>
                        <li className={classes.liStyle}>
                          Abstenerse de privilegiar los intereses de algún
                          Cliente sobre otro o actuar con conflictos de interés
                          en la ejecución de estos;
                        </li>
                        <li className={classes.liStyle}>
                          Mantener informados a los Clientes de los actos
                          realizados en la ejecución de dichas comisiones;
                        </li>
                      </ol>
                      <li className={classes.liStyle}>
                        Para efectos de claridad, las Partes reconocen que
                        conforme a lo previsto en la Ley para Regular las
                        Instituciones de Tecnología Financiera, CFL tienen
                        prohibido asegurar retornos o rendimientos sobre las
                        inversiones realizadas a través de la Plataforma o
                        garantizar el resultado o éxito de las inversiones.
                        financiamiento.
                      </li>
                    </li>
                    <li className={classes.liStyle}>
                      Cada vez que CFL celebre un Contrato de Crédito en nombre
                      y representación del Inversionista, enviará una
                      notificación al Inversionista respectivo a través de la
                      Plataforma en la que indicará, entre otras cosas, (i) el
                      monto invertido por el mismo en el Crédito de que se
                      trate, (ii) la fecha de pago del capital invertido y los
                      Medios de Disposición de los mismos, y (iii) la
                      metodología para el cálculo de los rendimientos, la
                      periodicidad para el pago de los mismos y los medios de
                      disposición de dichos rendimientos, ya sea que se
                      entreguen de forma periódica (en cuyo caso señalará además
                      la fecha de corte) o al final del proyecto o
                      financiamiento.
                    </li>
                    <li className={classes.liStyle}>
                      Mientras no se hubiere asignado algún Crédito para
                      inversión del Inversionista, o cuando no se hubiere
                      obtenido el monto requerido para celebrar una operación al
                      término del plazo en que la solicitud de financiamiento
                      colectivo correspondiente debía mantenerse publicada en la
                      Plataforma, los recursos del Inversionista permanecerán en
                      la Cuenta Concentradora de Clientes a la espera de nuevos
                      proyectos de financiamiento colectivo. De igual manera,
                      cuando conforme a lo establecido en el Contrato de
                      Crédito, no se hubieren cumplido las condiciones
                      suspensivas para el desembolso del Crédito y/o el
                      Solicitante de que se trate no hubiere confirmado la
                      celebración de la operación al término del Plazo de
                      Solicitud, los recursos del Inversionista destinados a
                      dicho Crédito permanecerán en la Cuenta Concentradora de
                      Clientes, a la espera de nuevos proyectos de
                      financiamiento colectivo. En los supuestos previstos en
                      este párrafo, si el Inversionista deseara retirar sus
                      fondos, sólo tendrá que solicitar el retiro de los mismos
                      a través de la Plataforma y CFL transferirá los montos
                      correspondientes a la Cuenta Bancaria del Inversionista
                      dentro de los 3 (tres) Días Hábiles siguientes a dicha
                      solicitud, sin restricción o cargo alguno.
                    </li>
                    <li className={classes.liStyle}>
                      En relación con lo anterior, se hace notar que el sistema
                      de asignación de cartera que se utiliza en la Plataforma,
                      no se trata de una inversión automática, sino que es un
                      sistema que permite respetar el orden de llegada de los
                      solicitantes y cumplir con los límites de diversificación,
                      pero la asignación no es ejecutada automáticamente, sino
                      que se le informará al Inversionista a través de la
                      Plataforma el resultado de la asignación; es decir, se le
                      informará qué créditos se encuentran disponibles para
                      invertir para que él, los tome en consideración al momento
                      de su inversión (indicando el monto total solicitado para
                      cada proyecto a financiar, el monto de los recursos que
                      aportará el Inversionista, y los medios a través de los
                      cuales se recibirán las inversiones correspondientes) y
                      los límites de diversificación y él tendrá que seleccionar
                      la opción para invertir.
                    </li>
                    <li className={classes.liStyle}>
                      En todo caso, transcurridos 27 días naturales sin que los
                      fondos del Inversionista disponibles en su Cuenta de
                      Cliente hayan sido invertidos (ya sea por falta de ofertas
                      en el mercado que pudieran ser empatadas con los
                      Requisitos del Producto Relevantes o por cualquier otro
                      motivo), CFL enviará un correo al Inversionista informando
                      dicha circunstancia y notificándose que, salvo instrucción
                      en contrario girada por el Inversionista respectivo a CFL
                      a través de la Plataforma o salvo que dichos fondos sean
                      invertidos dentro de los 3 días naturales siguientes, CFL
                      devolverá dichos montos al Inversionista mediante la
                      transferencia de los mismos a la Cuenta Bancaria del
                      Inversionista al término de 3 días naturales siguientes a
                      la fecha de dicho correo electrónico, siempre y cuando
                      dichos fondos no estén sujetos a algún tipo promoción
                      ofertada por la Plataforma. Los fondos que se encuentren
                      en custodia a la espera de ser invertidos se mantendrán
                      por CFL en cuentas de depósito a la vista abiertas a
                      nombre de CFL con alguna institución de crédito para
                      recibir depósitos de dinero, que serán diferentes de
                      aquellas en las que se mantienen los recursos propios de
                      la operación de CFL, o podrán emplearse en operaciones de
                      reporto solo con valores emitidos por el Gobierno Federal
                      o el Banco de México celebradas al efecto, o bien
                      afectarse en un fideicomiso de administración constituido
                      para tal fin, que únicamente lleve a cabo las referidas
                      operaciones de reporto.
                    </li>
                    <li className={classes.liStyle}>
                      Cada vez que un Solicitante realice algún pago conforme a
                      su Contrato de Crédito, los recursos que reciba CFL y que
                      correspondan al Inversionista conforme a la parte del
                      Crédito de la que sea titular permanecerán en la Cuenta
                      Concentradora de Clientes hasta que (i) sean reinvertidos
                      si el Inversionista mantiene activa la función de
                      Reinversión, o (ii) el Inversionista solicite el retiro de
                      los mismos si no mantiene activa la función de
                      Reinversión.
                    </li>
                    <li className={classes.liStyle}>
                      Conforme a lo previsto en el Artículo 45 de la Ley para
                      Regular las Instituciones de Tecnología Financiera, CFL
                      únicamente recibirá recursos de sus Clientes que provengan
                      directamente de cuentas de depósito de dinero abiertas en
                      una entidad financiera autorizada para recibir dichos
                      depósitos. Asimismo, CFL entregará los recursos a sus
                      Clientes mediante abonos o transferencias a las
                      respectivas cuentas que estos mantengan abiertas en
                      entidades financieras y que designen para tal efecto.
                    </li>
                    <li className={classes.liStyle}>
                      CFL, para la ejecución de las comisiones otorgadas para la
                      realización de la cobranza extrajudicial de los Créditos,
                      informará al Inversionista las acciones que llevará a cabo
                      (por sí o a través de terceros) para realizar la cobranza
                      extrajudicial, así como los alcances y objetivos que se
                      pretenden con dicha gestión, incluyendo la resolución de
                      controversias por la vía conciliatoria o de arbitraje. CFL
                      informará al Inversionista a través de la Plataforma
                      dentro de los 10 días hábiles siguientes, la realización
                      de actos de cobranza y representación relacionados con las
                      comisiones aquí otorgadas (ya sea que dichos actos los
                      haya realizado por sí o a través de terceros), y
                      adicionalmente informará al Comitente a través de la
                      Plataforma el estado en que se encuentre cualquier proceso
                      de cobranza extrajudicial que se esté llevando a cabo (ya
                      sea por sí o a través de terceros). Todas las gestiones de
                      cobranza que realice CFL en el ejercicio de las comisiones
                      aquí otorgadas se sujetarán a lo previsto en el Manual de
                      Cobranza de CFL.
                    </li>
                    <li className={classes.liStyle}>
                      Conforme a lo previsto arriba, CFL, en nombre y
                      representación del Inversionista, podrá pactar con los
                      Solicitantes modificaciones a las condiciones originales
                      para reestructurar o renovar los Créditos, hacer quitas o
                      recibir en pago bienes, realizar operaciones de factoraje,
                      venta o cesión de derechos de cobro que tenga el
                      Comitente, durante la recuperación extrajudicial, conforme
                      a los siguientes términos y condiciones:
                      <ol>
                        <li className={classes.liStyle}>
                          Cualquier modificación a las condiciones originales
                          para reestructurar o renovar los Créditos se hará
                          previo consentimiento del Inversionista en caso de que
                          pueda afectar a la rentabilidad esperada de los
                          mismos, y en ningún caso se garantiza a los
                          Solicitantes que las condiciones originales pueden ser
                          modificadas.
                        </li>
                        <li className={classes.liStyle}>
                          Del mismo modo, no se garantiza a los Solicitantes
                          quitas o recibir en pago bienes y realizar operaciones
                          de factoraje, y dichas acciones se harán únicamente
                          con el previo consentimiento del Inversionista,
                          siempre y cuando pueda afectar a la rentabilidad
                          esperada de los mismos
                        </li>
                        <li className={classes.liStyle}>
                          No se permite la venta o cesión de derechos de cobro
                          de préstamos o Partes del Crédito entre Inversionistas
                          (venta secundaria) que sean considerados como
                          incobrables.
                        </li>
                        <li className={classes.liStyle}>
                          Un crédito incobrable se considera así cuando un
                          Solicitante tiene un retraso en el pago de sus
                          obligaciones superior a 90 días y transcurridos 30
                          días posteriores a esos 90 no se ha alcanzado un
                          acuerdo de pagos. Los Inversionistas serán informados
                          de tal situación, siempre y cuando su rentabilidad
                          esperada de acuerdo con los productos seleccionados
                          pueda verse afectada por tales hechos
                        </li>
                      </ol>
                    </li>
                    <li className={classes.liStyle}>
                      Las obligaciones de CFL como comisionista son las que se
                      estipulan expresamente en el Contrato de Comisión
                      Mercantil, en estos Términos, en los Contratos de Crédito
                      respectivos y en los Documentos del Crédito relacionados.
                      Ni CFL, ni sus directores, funcionarios, agentes o
                      empleados serán responsables ante los Inversionistas por
                      causa de cualquier acción u omisión derivado de los mismos
                      excepto que se deba a su propio dolo, negligencia grave o
                      mala fe. Sin limitar la generalidad de lo anterior, CFL no
                      incurrirá en responsabilidad alguna para con los
                      Inversionistas por actuar con base en instrucciones
                      realizadas a través de la Cuenta CFL del Inversionista
                      correspondiente. Aunado a lo anterior, CFL: (A) No
                      declarar y no será responsable ante Inversionista alguno
                      de la exactitud e integridad de cualquier información
                      (excepto por información que haya analizado CFL en el
                      proceso de evaluación, selección y calificación de los
                      Solicitantes), garantía o declaración ya sea verbal o
                      escrita, hecha y otorgada conforme a cualquier Contrato de
                      Crédito, Documento del Crédito y/o en relación con los
                      mismos; y (B) no será responsable por la correcta
                      ejecución, observancia, suficiencia, cobrabilidad o valor
                      de los Contratos de Crédito, de los Documentos del
                      Crédito, ni de cualquier otro instrumento o documento
                      proporcionado conforme a dichos documentos, salvo por los
                      perjuicios que causare: (i) su omisión o tardanza en la
                      verificación oportuna de la cobranza de los créditos y el
                      uso de los medios legales para conseguir el pago conforme
                      a lo establecido en el Artículo 303 del Código de
                      Comercio, y (ii) el incumplimiento por parte de CFL a lo
                      establecido en la legislación aplicable y en este
                      Contrato; en el entendido que si el incumplimiento fuere
                      en virtud de órdenes expresas del Inversionista, las
                      responsabilidades a que haya lugar pesarán sobre ambos
                    </li>
                    <li className={classes.liStyle}>
                      El Inversionista podrá revocar en cualquier momento las
                      comisiones otorgadas a través del correo electrónico a
                      complaints@capitalfundinglab.com o solicitando el retiro
                      total de su inversión a través de los medios disponibles
                      en la Plataforma; dicha revocación surtirá efectos a los 3
                      (tres) Días Hábiles siguientes a la fecha en que la misma
                      se realice por el Inversionista a través de dicha
                      Plataforma. El día en que CFL reciba una solicitud de
                      terminación (o el Día Hábil inmediato siguiente en caso de
                      que la solicitud se presente en un día inhábil bancario),
                      proporcionará al Inversionista a través de la Plataforma
                      y/o por correo electrónico, un número de referencia de
                      dicha solicitud (así como la fecha y hora en que esta se
                      recibió) y solicitará una confirmación del Inversionista a
                      fin de cerciorarse de la autenticidad y veracidad de la
                      identidad del mismo en la formulación de la solicitud de
                      terminación respectiva; en el entendido, que al Día Hábil
                      siguiente a aquél en que el CFL reciba la solicitud de
                      terminación, informará al Inversionista a través de la
                      Plataforma y por correo electrónico, los saldos que
                      mantenga invertidos y disponibles. En caso de que existan
                      recursos invertidos en Créditos, CFL entregará los
                      recursos e intereses generados una vez que concluya el
                      plazo de vencimiento de los mismos o, en su caso, el monto
                      de la contraprestación por la cesión de los mismos
                      conforme al procedimiento previsto para tal efecto en la
                      Plataforma.
                    </li>
                    <li className={classes.liStyle}>
                      CFL acepta los términos contenidos en estos Términos y
                      Condiciones, el Contrato de Comisión Mercantil respectivo,
                      los Contratos de Crédito correspondientes y los demás
                      Documentos del Crédito relacionados
                    </li>
                    <li className={classes.liStyle}>
                      A continuación incluimos para su referencia la
                      transcripción de los Artículos 273 y 274 del Código de
                      Comercio referidos arriba:
                      <ol>
                        <li className={classes.liStyle}>
                          Artículo 273.- El mandato aplicado a actos concretos
                          de comercio, se reputa comisión mercantil. Es
                          comitente el que confiere comisión mercantil y
                          comisionista el que la desempeña.
                        </li>
                        <li className={classes.liStyle}>
                          Artículo 274.- El comisionista, para desempeñar su
                          encargo, no necesitará poder constituido en escritura
                          pública, siéndole suficiente recibirlo por escrito o
                          de palabra; pero cuando haya sido verbal se ha de
                          ratificar por escrito antes que el negocio concluya.
                        </li>
                        <li className={classes.liStyle}>
                          Artículo 285 . Cuando el comisionista contratare
                          expresamente en nombre del comitente, no contraerá
                          obligación propia, rigiéndo se en este caso sus
                          derechos y obligaciones como simple mandatario
                          mercantil, por las disposiciones del derecho común.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>VIII. Obtención de Créditos a través de CFL</b>
              <Typography variant="body1" gutterBottom inline>
                <ol>
                  <li>
                    Solicitud para obtener un Crédito de CFL
                    <ol>
                      <li className={classes.liStyle}>
                        Si desea solicitar un Crédito puede realizar una
                        solicitud (“Solicitud de Crédito”) a través de la
                        Plataforma, la cual se puede hacer por montos entre
                        $100,000.00 y el equivalente a 50,000 UDIs, y en caso de
                        que CFL los considere aptos para otorgarles el crédito
                        les proporcionará una cotización.
                      </li>
                      <li>
                        Una cotización proporcionada por CFL no equivale a:
                        <ol>
                          <li>una garantía u oferta de Crédito, ni a</li>
                          <li>
                            Una promesa de que los términos de la cotización
                            serán los mismos a los contenidos, en su caso, en su
                            Propuesta de Crédito Personal.
                          </li>
                        </ol>
                      </li>
                      <li>
                        Al realizar una Solicitud de Crédito, usted acepta:
                        <ol>
                          <li>
                            Proporcionarnos cualquier información adicional que
                            podamos razonablemente requerir para hacer una
                            evaluación de:
                            <ol>
                              <li>
                                la probabilidad de que usted pague el crédito
                                (su riesgo crediticio); y
                              </li>
                              <li>
                                su capacidad para pagar el Crédito y el impacto
                                en sus finanzas generales (asequibilidad).
                              </li>
                            </ol>
                          </li>
                          <li>
                            Usted debe asegurarse de que toda la información que
                            nos proporcione durante el proceso de solicitud sea
                            verdadera y exacta. Si usted nos proporciona
                            información falsa, inexacta o engañosa u omite
                            cualquier información que haga que la información
                            sea deficiente en cualquier aspecto, constituirá un
                            evento de incumplimiento, permitiéndonos considerar
                            que el Crédito es pagadero de inmediato, y lo
                            reportaremos a las agencias de calificación
                            crediticia y de fraude; y
                          </li>
                          <li>
                            Acepta que revisemos su Calificación Crediticia y la
                            información que usted nos proporcione para evaluar
                            su capacidad de pago.
                          </li>
                        </ol>
                      </li>
                      <li>
                        Si se le proporciona una Propuesta de Crédito Personal,
                        puede aceptarla, en cuyo caso la Plataforma intentará
                        empatar su Solicitud de Crédito con las Ofertas de
                        Mercado. Si su Solicitud de Crédito no es empatada
                        dentro de cinco Días Hábiles podemos, a nuestra
                        discreción, tratar su Solicitud de Crédito como
                        caducada.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Constitución de Contratos de Crédito
                    <ol>
                      <li>
                        Al aceptar una Propuesta de Crédito Personal, usted
                        acepta cada una de las Ofertas de Mercado que coincidan
                        con su Solicitud de Crédito.
                      </li>
                      <li>
                        Los Solicitantes e Inversionistas reconocen y aceptan
                        que los Inversionistas celebrarán los Contratos de
                        Crédito por conducto de CFL conforme a lo establecido en
                        los presentes Términos y Condiciones, en el Contrato de
                        Comisión Mercantil, el Contrato de Crédito respectivo y
                        los Documentos del Crédito. Cada Crédito podrá ser
                        otorgado por un solo Inversionista a un Solicitante o
                        podrá comprender varias Partes del Crédito otorgadas por
                        distintos Inversionistas cada una, de acuerdo con los
                        límites de diversificación que deben ser aplicados a
                        cadaInversionista de acuerdo con los establecido en las
                        Disposiciones de Carácter General Aplicables a las
                        Instituciones de Tecnología Financiera y que se
                        describen de manera general en la Sección VII
                        (Otorgamiento de Créditos a través de CFL), apartado 2,
                        párrafo D de estos Términos, en cuyo caso, el
                        otorgamiento de cada Parte del Crédito constituye una
                        relación contractual separada entre cada Inversionista y
                        el Solicitante correspondiente, bajo los términos del
                        Contrato de Crédito.
                      </li>
                      <li>
                        Cuando exista más de un Inversionista:
                        <ol>
                          <li className={classes.liStyle}>
                            El Solicitante será responsable en forma separada
                            frente a cada Inversionista, en proporción al monto
                            de la Parte del Crédito que otorgó ese
                            Inversionista, junto con los intereses
                            correspondientes a esa Parte del Crédito; y
                          </li>
                          <li className={classes.liStyle}>
                            Cada pago debe ser realizado por el Solicitante en
                            la fecha de pago correspondiente y estará integrado
                            por los siguientes conceptos: (i) el monto de
                            principal de todas las Partes del Crédito; (ii) los
                            intereses generados sobre cada una de las Partes del
                            Crédito, y (iii) cualesquier otras cantidades
                            adeudadas por el Solicitante a los Inversionistas
                            y/o a CFL, incluyendo sin limitar cualesquiera de
                            las comisiones, indemnizaciones, honorarios, costos
                            y gastos.
                          </li>
                        </ol>
                      </li>
                      <li>
                        En todos los Contratos de Crédito y en toda la
                        documentación relacionada (incluyendo los demás
                        Documentos del Crédito) haremos referencia al domicilio
                        del Inversionista como “c/o CFL” e indicaremos nuestra
                        dirección postal. Cada Solicitante acuerda y reconoce
                        que todas las notificaciones y comunicaciones que se le
                        den a un Inversionista serán enviadas a CFL en
                        representación de dichos Inversionistas y que esto es
                        suficiente para identificar a los Inversionistas para
                        los fines establecidos en los Contratos de Crédito.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Garantía
                    <ol>
                      <li className={classes.liStyle}>
                        Toda Propuesta de Crédito Personal requerirá el
                        otorgamiento por parte del Solicitante, de cierta
                        garantía real (la “Garantía”), y en algunos casos podrá
                        incorporarse además una garantía personal; lo anterior
                        será determinado caso por caso dependiendo de la
                        naturaleza de la Solicitud de Crédito correspondiente
                      </li>
                      <li className={classes.liStyle}>
                        CFL tiene el derecho a retener el desembolso del Crédito
                        hasta el momento en que dicha garantía que, a discreción
                        se considere adecuada para proteger los intereses de los
                        Inversionistas, se encuentre debidamente constituida y
                        perfeccionada en favor de CFL, en nombre y para
                        beneficio de los Inversionistas en términos de los
                        correspondientes Contratos de Comisión Mercantil.
                      </li>
                      <li className={classes.liStyle}>
                        El Crédito se considerará terminado y asumiremos que el
                        Solicitante ha ejercido su derecho a retirarse en caso
                        que la Garantía no sea debidamente otorgada y
                        perfeccionada conforme a lo establecido en el Contrato
                        de Crédito correspondiente, en los siguientes plazos (i)
                        15 Días Hábiles siguientes a la celebración del Contrato
                        de Crédito de “Préstamos Liquidez” o “Préstamos Liquidez
                        Empresas”; (ii) 5 Días Hábiles siguientes a la
                        celebración del Contrato de Crédito de “Préstamos
                        Hogar”, o (iii) dentro de cualquier otro período más
                        largo que se acuerde expresamente con el Solicitante,,
                        de forma que cualquier obligación de los Inversionistas
                        para desembolsar el Crédito conforme al Contrato de
                        Crédito quedará extinguida sin responsabilidad alguna
                        para los Inversionistas mencionados.
                      </li>
                      <li className={classes.liStyle}>
                        Los Inversionistas reconocen y aceptan que si bien la
                        toma de Garantías puede ofrecer cierta protección, esto
                        no garantiza que el Crédito será reembolsado en su
                        totalidad o que el Inversionista recuperará su
                        desembolso inicial.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Desembolso de los Fondos del Crédito
                    <ol>
                      <li className={classes.liStyle}>
                        Para todos los Créditos, el pago a la Cuenta Bancaria
                        del Solicitante está sujeto a que se reciba la
                        información especificada en el Contrato de Crédito en la
                        Solicitud del mismo y en estos Términos y Condiciones
                      </li>
                    </ol>
                  </li>
                </ol>
              </Typography>
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>IX. Administración del Crédito por CFL</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              <ol>
                <li>
                  Pagos
                  <ol>
                    <li className={classes.liStyle}>
                      Para el pago del Crédito, el Solicitante deberá completar
                      válidamente una transferencia de fondos inmediatamente
                      disponibles (u otro método de pago aprobado por nosotros),
                      a la Cuenta Concentradora de Clientes descrita en su
                      respectivo Contrato de Crédito e indicando su referencia
                      única; todas las sumas recibidas por CFL de los
                      Solicitantes se utilizarán para el pago de los Créditos
                      correspondientes, en forma de pagos o abonos a las Cuentas
                      de Cliente de los Inversionistas respectivos.
                    </li>
                  </ol>
                </li>
                <li>
                  Incumplimiento de Pago
                  <ol>
                    <li>
                      Si usted incumple un Pago, o se encuentra en dificultades
                      financieras y considera que no puede pagar la cantidad
                      adeudada en virtud de cualquiera de sus Contratos de
                      Crédito, debe ponerse en contacto con nosotros tan pronto
                      como sea posible por correo electrónico a:
                      info@capitalfundinglab.com, por su parte, CFL hará lo
                      siguiente:
                      <ol>
                        <li>
                          explicar los procedimientos que aplicaremos y sus
                          opciones; y
                        </li>
                        <li>
                          Le informaremos si su cuenta está siendo transferida a
                          la Agencia de Cobranzas
                        </li>
                      </ol>
                    </li>
                    <li className={classes.liStyle}>
                      Como Solicitante, usted puede ser contactado con relación
                      a los montos pendientes de pago de cualquiera de sus
                      Contratos de Crédito por CFL o por la Agencia de
                      Cobranzas, dependiendo de:
                      <ol>
                        <li>
                          La naturaleza de la cantidad adeudada y el tiempo que
                          lleva pendiente de pago y;
                        </li>
                        <li>
                          La entidad está obligada a enviarle una notificación
                          de incumplimiento de acuerdo a la Ley Aplicable.
                        </li>
                      </ol>
                    </li>
                    <li className={classes.liStyle}>
                      Debe informar a CFL o a la Agencia de Cobranzas, según sea
                      el caso, si sus circunstancias cambian de una manera que
                      pueda afectar adversamente su capacidad de pagar la
                      cantidad adeudada en virtud de cualquiera de sus Contratos
                      de Crédito.
                    </li>
                    <li className={classes.liStyle}>
                      Debe tener en cuenta, que la falta de pago total cuando se
                      requiera puede afectar su calificación Crediticia y tener
                      serias consecuencias para su capacidad de obtener un
                      crédito en el futuro.
                    </li>
                  </ol>
                </li>
                <li>
                  Cobranzas después del Incumplimiento
                  <ol>
                    <li className={classes.liStyle}>
                      En caso de incumplimiento del Solicitante a los términos
                      de un Contrato de Crédito, CFL iniciará acciones para
                      recuperar la deuda pendiente y tomará las medidas que sean
                      razonablemente necesarias, legales y apropiadas para
                      recuperar cualquier cantidad pendiente. Cualesquier sumas
                      recuperadas por CFL (o por la Agencia de Cobranzas) serán
                      pagadas a los Inversionistas correspondientes, en
                      cualquier caso, sujeto a la deducción de los costos de
                      recuperación en los que incurra CFL y/o la Agencia de
                      Cobranzas (los cuales serán prorrateados de acuerdo con la
                      proporción que cada Parte del Crédito correspondiente
                      represente con respecto al monto total del Crédito de que
                      se trate).
                    </li>
                    <li className={classes.liStyle}>
                      Para efectos de lo anterior, en caso de que algún Crédito
                      sea determinado como incobrable conforme a la Política de
                      Cobranza de CFL, se mandará el expediente al departamento
                      legal para gestionar la ejecución de la garantía
                      correspondiente en términos de la legislación aplicable.
                      En dicho caso, CFL contactará a los Inversionistas
                      correspondientes a fin de notificarles la incobrabilidad
                      del crédito respectivo y mantenerlos informados de los
                      esfuerzos judiciales y extrajudiciales que procedan para
                      la ejecución de las Garantías correspondientes. CFL
                      ejecutará las Garantías conforme a lo previsto en la
                      legislación aplicable y en los documentos en los que
                      conste la constitución de dichas Garantías, y aplicará el
                      producto de la venta, disposición u otra ejecución de las
                      mismas al pago de todas y cada una de las obligaciones de
                      pago a cargo del Solicitante correspondiente y que
                      permanezcan insolutas conforme al Contrato de Crédito de
                      que se trate y cualquier otro Documento del Crédito
                      relacionado, en el orden establecido para la aplicación de
                      pagos en dicho Contrato de Crédito, incluyendo, sin
                      limitación alguna, todos los montos de principal,
                      intereses, intereses moratorios, comisiones, cargos,
                      costos y gastos (incluyendo honorarios y gastos de
                      asesores legales), y todos los impuestos, derechos,
                      cargas, deducciones o retenciones, causadas o derivadas en
                      relación con el Contrato de Crédito y/o con el
                      procedimiento de ejecución de las Garantías.
                    </li>
                  </ol>
                </li>
                <li>
                  Cambios en la fecha de pago
                  <ol>
                    <li className={classes.liStyle}>
                      CFL entiende que un cambio ocasional en las circunstancias
                      personales y laborales del Solicitante (que no sea la
                      imposibilidad de realizar un Pago) puede significar que
                      usted quiera cambiar la fecha del mes en el que realiza
                      sus Pagos (la “Fecha de Pago”), en cuyo caso:
                      <ol>
                        <li className={classes.liStyle}>
                          Los Solicitantes pueden solicitar a CFL que cambie la
                          Fecha de Pago por correo electrónico a
                          info@capitalfundinglab.com o llamándonos al número de
                          contacto general que aparece en el Sitio Web de CFL
                          (dichas llamadas serán grabadas para confirmar su
                          consentimiento con respecto al cambio).
                        </li>
                        <li className={classes.liStyle}>
                          Dependiendo de si la Fecha de Pago revisada es
                          anterior o posterior a la Fecha de Pago original, el
                          importe de su próximo Pago en la nueva Fecha de Pago
                          se ajustará para tener en cuenta el número de días
                          entre la nueva Fecha de Pago y la anterior. Los Pagos
                          restantes (suponiendo que no haya más cambios)
                          volverán al importe de Pago habitual.
                        </li>
                        <li className={classes.liStyle}>
                          CFL reconocerá y confirmará la nueva Fecha de Pago y
                          cualquier cambio en el monto de su próximo Pago, antes
                          de efectuar cualquier cambio, este acuse de recibo y
                          confirmación tendrá lugar durante el curso de su
                          llamada telefónica o mediante un intercambio de
                          correos electrónicos con usted.
                        </li>
                        <li className={classes.liStyle}>
                          CFL puede solicitar una justificación para realizar el
                          cambio en la fecha de pago y se reserva el derecho de
                          no completar la solicitud si, a su entera y absoluta
                          discreción, determina que el cambio no se debe a un
                          cambio apropiado en las circunstancias o que no se
                          está siguiendo correctamente el proceso establecido
                          anteriormente.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>X. Su Cuenta de Cliente</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              <ol>
                <li className={classes.liStyle}>
                  CFL podrá pagar intereses sobre la cantidad de dinero que se
                  mantenga en su Cuenta de Cliente Inversionista, sin estar
                  obligado a pagar intereses por aquellos fondos que no hayan
                  sido utilizados en operaciones de financiamiento colectivo, en
                  el caso de que los pagase será en virtud a promociones
                  efectuadas por la Sociedad.{" "}
                  <b>
                    Estas promociones son algo que CFL podría aplicar en caso de
                    que así lo viese oportuno pero en ningún caso se trata de
                    algo que se asegure o garantice.
                  </b>
                </li>
                <li className={classes.liStyle}>
                  Si usted es un Inversionista, en el momento en que lo deseé,
                  puede solicitar a través de la Plataforma o por correo
                  electrónico, la transferencia de cualquier cantidad que tenga
                  en su Cuenta de Cliente Inversionista a su Cuenta Bancaria ,
                  siempre y cuando las cantidades que sean objeto de Ofertas de
                  Mercado empatadas aún no hayan sido desembolsadas al
                  Solicitante, así como cualquier cargo acumulado, pero no
                  cobrado. Si se descubre que un Contrato de Crédito es nulo
                  (por ejemplo, después de realizar comprobaciones adicionales),
                  la cantidad que usted ha prestado de conformidad con ese
                  Contrato de Crédito será invertida automáticamente en otro
                  Contrato de Crédito.
                </li>
              </ol>
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>XI. TARIFAS Y CARGOS</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              <ol>
                <li className={classes.liStyle}>
                  Todos los costos y cargos aplicables al Crédito del
                  Solicitante se establecen en la Propuesta de Crédito Personal
                  y en el Contrato de Crédito asociado a éste.
                </li>
                <li className={classes.liStyle}>
                  Todas las comisiones ordinarias pagaderas por el Solicitante a
                  CFL por concertar y administrar el Crédito durante el plazo
                  del Crédito están incluidas en el CAT que aplicamos al
                  Crédito.
                </li>
                <li className={classes.liStyle}>
                  Las tarifas pagadas por el Solicitante están relacionadas con
                  el perfil de riesgo del Solicitante y el Plazo del Crédito.
                </li>
                <li className={classes.liStyle}>
                  La Propuesta de Crédito Personal contendrá un detalle de las comisiones 
                  pagaderas y el costo del Crédito, por lo que tiene derecho a no proceder
                  con un Contrato de Crédito si no desea pagar las comisiones y costos ahí
                  descritos. En relación con lo anterior, CFL cobrará a sus Clientes
                  Solicitantes una Comisión de Apertura como los Gastos de Administración
                  En este sentido, la Comisión de Apertura, debe pagarse una vez que se 
                  haya formalizado el Crédito conforme a lo establecido en cada Contrato
                  de Crédito y ésta depende tanto de la calificación crediticia del
                  Solicitante como del plazo del Crédito A continuación, 
                  se incluye el detalle para el cálculo de la Comisión de Apertura:
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      {" "}
                      <img
                        src={ComisionApertura1}
                        alt="Comisión por apertura"
                        className={classes.imgSize}
                      />
                    </Grid>{" "}
                    <Grid item xs={12} md={12}>
                      {" "}
                      <img
                        src={ComisionApertura2}
                        alt="Comisión por apertura"
                        className={classes.imgSize}
                      />
                    </Grid>
                  </Grid>
                </li>
                <li className={classes.liStyle}>
                  Como se ha indicado, la Comisión de Apertura debe pagarse una
                  vez formalizado el Crédito conforme a lo establecido al
                  respecto en cada Contrato de Crédito y dicho porcentaje de
                  comisión de apertura (indicado en la tabla superior) dependerá
                  tanto de la calificación crediticia del Acreditado como del
                  plazo del Crédito. Se calculará como: Comisión de
                  Apertura=Monto Solicitado*% Comisión de Apertura (3.5%-29%).
                </li>
                <li className={classes.liStyle}>
                  Comisión por Manejo de Cuenta por Saldo Promedio Mensual
                  Inferior al mínimo. Se cobrará una comisión anual de $1,200.00
                  (Mil doscientos pesos 00/100 M.N.) si no cubre el Saldo
                  Promedio Mensual requerido, el cual es de $10,000.00 (Diez mil
                  pesos 00/100 M.N.), al último día del mes de diciembre que
                  corresponda a cada año."
                </li>
                {/* <li className={classes.liStyle}>
                  A continuación, se incluye el detalle de la Comisión de
                  Gestión:
                </li> */}
                {/* <Box className={classes.boxImg}>
                  <img src={ComisionGestion} alt="Comisión de gestión" />
                </Box>
                <li className={classes.liStyle}>
                  Como se ha indicado, la Comisión de Gestión se paga
                  mensualmente, y dicho porcentaje de comisión de gestión
                  depende de la calificación crediticia del Solicitante. Se
                  calculará como: Comisión de Gestión=Monto Solicitado*%
                  Comisión de Gestión (0.1%-0.95%)
                </li> */}
                <li className={classes.liStyle}>
                  Adicionalmente, los Inversionistas tendrán que pagar una
                  Comisión por Administración contemplada dentro de la
                  rentabilidad esperada de cada Producto de CFL para los
                  Inversionistas y por lo tanto, no supondrá ninguna minoración
                  de esta rentabilidad esperada para los Inversionistas. En este
                  sentido, la Comisión por Administración es el resultado de la
                  diferencia entre la Tasa de Interés pagada por el Solicitante
                  y la rentabilidad esperada por el Inversionista de acuerdo con
                  el Producto de CFL en el que éste último haya depositado sus
                  fondos. En relación con lo anterior, los Inversionistas sabrán
                  lo que tendrán que pagar en concepto de Comisión por
                  Administración en el momento en el que se le informe al
                  Inversionista a través de la Plataforma del resultado de la
                  asignación, es decir, qué hay disponible para él teniendo en
                  consideración el momento de su inversión y los límites de
                  diversificación, y él tendrá que seleccionar la opción a
                  invertir. En relación con lo anterior, a continuación se
                  incluye la metodología de cálculo de la comisión por
                  administración: Comisión por Administración =
                  (TNSolicitante1%Asignado1+TNSolicitante2%Asignado2+...+TNSolicitanten%Asignadon
                  ) - RE TN = Tasa Nominal Mensual RE = Rentabilidad Esperada
                  del producto seleccionado por el Inversionista en términos
                  mensuales.Esta Comisión por Administración puede verse
                  reducida por posibles promociones que se realicen por parte de
                  CFL, con el objeto de proporcionar una rentabilidad inmediata
                  a los Inversionistas y para favorecer el adecuado
                  funcionamiento del sistema de subastas o mercado secundario y
                  minorada por los costes derivados de mantener un nivel de
                  liquidez adecuado para el correcto funcionamiento del mercado
                  secundario. .{" "}
                  <b>
                    Estas promociones son algo que CFL podría aplicar en caso de
                    que así lo viese oportuno pero en ningún caso se trata de
                    algo que asegure o garantice. En relación con lo anterior,
                    si se aplicaran promociones la rentabilidad del
                    inversionista aumentaría ya que recibiría la Rentabilidad
                    Esperada (RE) más las promociones, mientras que la Comisión
                    por Administración se vería reducida tal y como se muestra a
                    continuación:{" "}
                  </b>
                  Comisión por Administración =
                  (TNSolicitante1%Asignado1+...+TNSolicitanten%Asignadon) - RE -
                  Promociones
                </li>
                <li className={classes.liStyle}>
                  Tarifa de Retiro, dicha tarifa es aplicable únicamente para
                  los productos denominados UNO y MERCADO, en este caso, si los
                  Usuarios solicitan retirar sus fondos con anterioridad al
                  plazo definido para los productos. Consiste en la aplicación
                  de una penalización proporcional al resultado del producto y
                  del tiempo transcurrido en días entre la fecha de solicitud de
                  retiro de fondos y la fecha de finalización, y el valor
                  presente de los préstamos que el Inversionista ha solicitado
                  retirar. La Tarifa de retiro se calcula de acuerdo con la
                  siguiente expresión: máx(0;cantidad retiro × mínimo(1-depósito
                  saldo de
                  cuenta,(fechafinalización-fecharetiro)×((2.5%+1)1/365-1)) En
                  el caso de que la tarifa de retiro fuese negativa, la misma no
                  sería de aplicación. Por tanto, de acuerdo con los presentes
                  Términos y Condiciones, el método de pago por parte del
                  Inversionista se establece como una minoración de los
                  intereses a percibir por sus fondos depositados, y en
                  consecuencia, en ningún caso, suponen una comisión adicional
                  sobre los fondos depositados, ni generan diferencia alguna
                  entre los fondos depositados y el valor presente de los
                  préstamos o partes de préstamos financiados. Cuando un
                  Inversionista ha recibido algún tipo de bono o promoción sobre
                  sus fondos invertidos, renuncia a cualquier reclamación al
                  mismo si retira sus fondos invertidos antes de la fecha de
                  finalización de la inversión de su producto. Para el caso de
                  UNO, esto ocurre 365 días después del inicio de la inversión.
                  En el caso de MERCADO, 1095 después del inicio de la
                  inversión.
                </li>
              </ol>
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>
                XII. Modificaciones a los Términos de CFL y a las Condiciones
                del Crédito
              </b>
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              <ol>
                <li>
                  Podemos variar estos Términos y Condiciones para adaptar lo
                  siguiente:
                  <ol>
                    <li>Comentarios de los Clientes;</li>
                    <li>
                      Cambios requeridos por terceros proveedores de servicios u
                      otras obligaciones contractuales;
                    </li>
                    <li>
                      Prácticas de la industria o mejoras (incluyendo operativas
                      en el Sitio Web o en la Plataforma);
                    </li>
                    <li>
                      La incorporación de nuevos tipos de productos disponibles
                      a través de la Plataforma;
                    </li>
                    <li>Cambios en la Ley Aplicable;</li>
                    <li>
                      Decisiones u órdenes de cualquier tribunal, árbitro,
                      autoridad reguladora o supervisora o cualquier compromiso
                      asumido en uno de estos contextos.
                    </li>
                  </ol>
                </li>
                <li className={classes.liStyle}>
                  Previo al cambio, notificaremos a los Clientes con al menos 30
                  días de anticipación por medio de correo electrónico y por
                  medio de la Plataforma. El Usuario podrá optar por dar por
                  cerrar su cuenta en la Plataforma (ya sea a través de la
                  Plataforma o enviando un correo electrónico a
                  info@capitalfundinglab.com) dentro de los treinta días
                  posteriores al aviso de modificación sin responsabilidad
                  alguna a su cargo, y bajo las condiciones pactadas
                  originalmente, debiendo cubrir, en su caso, los adeudos que se
                  generen hasta el término de la operación o el servicio, sin
                  que CFL pueda cobrar penalización alguna por dicha causa. Si
                  continúa utilizando la Plataforma después de que le hayamos
                  notificado el cambio, habrá aceptado las modificaciones de los
                  Términos y Condiciones, salvo comunicación en forma expresa a
                  CFL. CFL se encuentra facultada, sin necesidad de aprobación
                  de los Usuarios, para modificar, enmendar o suspender,
                  transitoria o definitivamente, los servicios prestados, el
                  contenido y las descripciones y explicaciones del Sitio Web y
                  de la Plataforma.
                </li>
                <li className={classes.liStyle}>
                  No obstante cualquier disposición en contrario contenida en
                  estos Términos y Condiciones, CFL informará a los usuarios
                  cualquier modificación a las comisiones aplicables a las
                  operaciones, así como las nuevas comisiones que pretenda
                  cobrar, con por lo menos treinta días naturales de
                  anticipación a la fecha prevista para que éstas surtan
                  efectos; en dicho supuesto, los Clientes tendrán derecho a dar
                  por terminada la prestación de los servicios que les otorgue
                  CFL, en caso de no estar de acuerdo con los nuevos montos, sin
                  que CFL pueda cobrarles cantidad adicional alguna por este
                  hecho, con excepción de los adeudos que ya se hubieren
                  generado a la fecha en que el Cliente solicite dar por
                  terminado el servicio y sujeto en todo caso a lo previsto en
                  la Sección XIV de estos Términos
                </li>
              </ol>
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>XIII. Productos de CFL</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              <ol>
                <li className={classes.liStyle}>
                  Los Clientes Inversionistas de CFL podrán depositar sus fondos
                  en uno o varios de los siguientes productos:
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      {" "}
                      <img
                        src={Producto}
                        alt="Comisión por apertura"
                        className={classes.imgSize}
                      />
                    </Grid>{" "}
                  </Grid>
                </li>
                <li className={classes.liStyle}>
                  En el momento en el que un Inversionista deposite fondos en su
                  Cuenta de Cliente, éstos serán asignados al producto “Cuenta”.
                  Posteriormente, es el Inversionista el que deberá repartir sus
                  fondos en uno o varios de los productos ofertados de acuerdo
                  con sus necesidades. En relación con la tabla anterior, a
                  continuación se incluye un ejemplo ilustrativo para una mayor
                  comprensión del funcionamiento y las diferencias que reporta a
                  los Inversionistas la elección de cada uno de los productos.
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      {" "}
                      <img
                        src={Fondos}
                        alt="Comisión por apertura"
                        className={classes.imgSize}
                      />
                    </Grid>{" "}
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      {" "}
                      <img
                        src={Limite}
                        alt="Comisión por apertura"
                        className={classes.imgSize}
                      />
                    </Grid>{" "}
                  </Grid>
                </li>
                <li className={classes.liStyle}>
                  En relación al ejemplo ilustrativo, se ha considerado que la
                  cantidad asignada a la solicitud, es decir, los fondos
                  enviados de las cuentas virtuales de cada uno de los
                  Inversionistas a la cuenta virtual del Solicitante es de 20
                  pesos mexicanos. Tal y como se puede observar en la tabla
                  anterior, al transcurso de un año, la inversión de cada uno de
                  los Inversionistas habrá generado los intereses que se indican
                  en la última columna. Es importante destacar que aunque los
                  Inversionistas reciben distintas rentabilidades, el
                  Solicitante al que se le han entregado estos fondos paga la
                  misma Tasa Nominal Anual, la diferencia es que con las
                  inversiones en el producto Cuenta CFL ingresa una comisión de
                  administración (diferencia entre lo pagado por el Solicitante
                  y la rentabilidad esperada del Inversionista) superior que con
                  las inversiones en el producto Mercado. Esta diferencia de
                  ingresos para CFL entre un producto y otro se fundamenta en la
                  flexibilidad de cada uno de los productos al tener cada uno de
                  ellos plazos de permanencia distintos. Uno de los objetivos
                  fundamentales de CFL es ofrecer productos sencillos para los
                  Inversionistas, ofreciendo una visión transparente de la
                  rentabilidad esperada de cada uno de los productos ofertados,
                  y cuyo diseño incluye cualesquiera de las comisiones
                  existentes, y por ende, en ningún caso, dichas comisiones
                  afectan a las rentabilidades esperadas establecidas. Todo
                  ello, acompañado de un proceso de inversión sencillo e
                  intuitivo. Estos productos están orientados para
                  Inversionistas mexicanos y siempre en moneda nacional.
                </li>
                <li>
                  Los Solicitantes pueden ser cualquier persona física de
                  nacionalidad mexicana o extranjera, residente en México, mayor
                  de 18 años o cualquier persona moral debidamente constituida y
                  tienen a su disposición tres tipologías de préstamos:
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      {" "}
                      <img
                        src={Limite}
                        alt="Comisión por apertura"
                        className={classes.imgSize}
                      />
                    </Grid>{" "}
                  </Grid>
                </li>
                <li className={classes.liStyle}>
                  La tipología de préstamos “Liquidez” y “Liquidez Empresas”,
                  están limitados a plazos de financiamiento de 12 a 120 meses,
                  con un límite máximo de financiamiento de 50,000 y 1,670,000
                  UDI’s respectivamente (313,467 y 10,467,775 MXN
                  aproximadamente). La tipología de préstamos denominada “Hogar”
                  está destinada a personas físicas residentes en México y
                  mayores de edad, cuando el destino del financiamiento es la
                  adquisición de vivienda. Para esta tipología el plazo de
                  financiamiento es de 12 a 240 meses, con un límite máximo de
                  50,000 UDI’s (313,467 MXN aproximadamente). Independientemente
                  de la tipología de préstamos, todos los financiamientos se
                  otorgan con garantía real (principalmente bienes inmuebles) y
                  se establecen seguros de vida para los solicitantes.
                  Adicionalmente, cabe destacar que todos los financiamientos
                  independientemente de su tipología se realizan en moneda
                  nacional. Por último, en relación con las tasas de
                  financiamiento (Tasas Nominales Anuales) ofrecidas a los
                  solicitantes, éstas dependen tanto del perfil de riesgo del
                  solicitante como del plazo de financiamiento solicitado y se
                  encuentran disponibles en la Plataforma. Todos los productos
                  de CFL tanto para Inversionistas como para Solicitantes, así
                  como las Tasas Nominales Anuales ofrecidas a los solicitantes
                  estarán disponibles, para su consulta, en el Sitio Web de CFL.
                </li>
              </ol>
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b> XIV. Cierre de su Cuenta de CFL</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              <ol>
                <li>
                  Derecho del Solicitante a Retirarse
                  <ol>
                    <li className={classes.liStyle}>
                      El derecho de un Solicitante a retirarse de cualquier
                      Contrato de Crédito se establecerá dentro de los términos
                      del Contrato de Crédito correspondiente.
                    </li>
                  </ol>
                </li>
                <li>
                  Derecho de Terminación.
                  <ol>
                    <li className={classes.liStyle}>
                      Su contrato con nosotros conforme a los presentes Términos
                      y Condiciones entrará en vigor en la fecha en que se
                      registre como Inversionista o Solicitante y continuará:
                      <ol>
                        <li className={classes.liStyle}>
                          Para Inversionistas, hasta que usted nos notifique a
                          través de su Cuenta CFL o enviando un correo
                          electrónico a info@capitalfundinglab.com o le
                          notifiquemos por escrito que su Cuenta CFL será
                          cerrada de conformidad con el Apartado 3 de esta misma
                          Sección, estando esto siempre sujeto a las condiciones
                          del Producto de CFL en el que el Inversionista haya
                          invertido.
                        </li>
                        <li className={classes.liStyle}>
                          Para Solicitantes, hasta 90 días después del pago de
                          todas las cantidades adeudadas en virtud de cualquier
                          Contrato de Crédito.
                        </li>
                      </ol>
                    </li>
                    <li className={classes.liStyle}>
                      Sujeto en todo caso a lo establecido en esta Sección, el
                      Solicitante y los Inversionistas estarán facultados para
                      solicitar que sea cerrada su cuenta en CFL en cualquier
                      momento a través de la Plataforma o enviando un correo
                      electrónico a info@capitalfundinglab.com. El día en que
                      CFL reciba una solicitud de terminación (o el Día Hábil
                      inmediato siguiente en caso de que la solicitud se
                      presente en un día inhábil bancario), CFL proporcionará al
                      Solicitante o al Inversionista, según corresponda, a
                      través de la Plataforma y/o por correo electrónico, un
                      número de referencia de dicha solicitud (así como la fecha
                      y hora en que esta se recibió) y solicitará una
                      confirmación del Solicitante o Inversionista, según sea el
                      caso, a fin de cerciorarse de la autenticidad y veracidad
                      de la identidad del mismo en la formulación de la
                      solicitud de terminación respectiva; en el entendido, que:
                      <ol>
                        <li>
                          Por lo que respecta a la terminación a solicitud de un
                          Solicitante:
                          <ol>
                            <li className={classes.liStyle}>
                              A más tardar el Día Hábil siguiente a aquél en que
                              CFL reciba la solicitud de terminación
                              correspondiente, comunicará al Solicitante a
                              través de la Plataforma y por correo electrónico,
                              el importe de sus adeudos de principal, intereses
                              (ya sean ordinarios o moratorios) y comisiones
                              adeudadas a dicha fecha y la proyección de los
                              mismos para los 5 (cinco) Días Hábiles inmediatos
                              siguientes, respecto de todos los Créditos que
                              tenga contratados a dicha fecha;
                            </li>
                            <li className={classes.liStyle}>
                              Una vez liquidados los adeudos correspondientes
                              considerando la fecha de liquidación de los
                              mismos, CFL procederá a cancelar su registro en
                              CFL;
                            </li>
                            <li className={classes.liStyle}>
                              En caso de que por cualquier motivo exista un
                              saldo a favor del Solicitante, CFL lo entregará al
                              Solicitante mediante depósito en la cuenta
                              bancaria que al efecto indique en la solicitud de
                              terminación, en la misma fecha en que cancele la
                              cuenta; y
                            </li>
                            <li className={classes.liStyle}>
                              En caso de que por cualquier motivo exista un
                              saldo a favor del Solicitante, CFL lo entregará al
                              Solicitante mediante depósito en la cuenta
                              bancaria que al efecto indique en la solicitud de
                              terminación, en la misma fecha en que cancele la
                              cuenta; y 4. Dentro de los 10 (diez) Días Hábiles
                              siguientes a la cancelación de la cuenta, CFL
                              proporcionará al Solicitante, mediante correo
                              electrónico, el estado de cuenta o documento en el
                              que conste el fin de la relación contractual aquí
                              contenida y la inexistencia de adeudos derivados
                              de dicha relación; y
                            </li>
                          </ol>
                        </li>
                        <li>
                          Por lo que respecta a la terminación por parte de un
                          Inversionista:
                          <ol>
                            <li className={classes.liStyle}>
                              A partir de la recepción de la solicitud de
                              terminación de que se trate, CFL realizará
                              esfuerzos razonables para asignar y ceder la Parte
                              del Crédito del Inversionista de que se trate en
                              los Créditos que mantenga a dicha fecha conforme a
                              la opción de cesión presente en la Plataforma;
                            </li>
                            <li className={classes.liStyle}>
                              El Inversionista que haya solicitado la
                              terminación no será liberado de sus obligaciones y
                              compromisos conforme a los Créditos en los que
                              tenga participación hasta que las Partes del
                              Crédito correspondientes hayan sido cedidas a un
                              tercero de conformidad con lo previsto en los
                              Contratos de Crédito y Comisión Mercantil
                              respectivos y en la legislación aplicable;
                            </li>
                            <li className={classes.liStyle}>
                              En caso de que por cualquier motivo exista un
                              saldo a favor del Inversionista, CFL lo entregará
                              al Inversionista mediante depósito en la cuenta
                              bancaria que al efecto indique en la solicitud de
                              terminación, en la misma fecha en que cancele la
                              cuenta; y
                            </li>
                            <li className={classes.liStyle}>
                              Dentro de los 10 (diez) Días Hábiles siguientes a
                              la cancelación de la cuenta, CFL proporcionará al
                              Inversionista correspondiente, mediante correo
                              electrónico, el estado de operación o documento en
                              el que conste el fin de la relación contractual
                              aquí contenida por lo que respecta exclusivamente
                              al Inversionista que haya solicitado la
                              terminación.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      CFL deberá
                      <ol>
                        <li className={classes.liStyle}>
                          Abstenerse de cobrar comisión o penalización por la
                          mera cancelación de una cuenta;
                        </li>
                        <li className={classes.liStyle}>
                          Abstenerse solicitar a los Solicitantes o a los
                          Inversionistas que informen o justifiquen los motivos
                          de la cancelación de su cuenta;
                        </li>
                        <li className={classes.liStyle}>
                          Cancelar, sin su responsabilidad, el cobro de algún
                          producto o servicio asociado, en la fecha de la
                          solicitud de terminación, y
                        </li>
                        <li className={classes.liStyle}>
                          Abstenerse de condicionar la cancelación de la cuenta
                          a cualquier otro acto no previsto en estos Términos y
                          Condiciones o en los Contratos de Crédito y Comisión
                          Mercantil que tengan celebrados
                        </li>
                      </ol>
                    </li>
                    <li className={classes.liStyle}>
                      Para efectos de claridad, se establece que en ningún
                      momento se les permitirá a los Inversionistas que cierren
                      sus cuentas mientras tengan una Parte del Crédito
                      existente en su cartera. La cancelación o terminación no
                      les dará derecho a retirarse de las Partes del Crédito a
                      las cuales se hayan comprometido. Los Inversionistas deben
                      estar preparados para mantener las Partes del Crédito
                      durante el plazo del Crédito. El plazo máximo de nuestros
                      créditos es de 240 meses.
                    </li>
                  </ol>
                </li>
                <li>
                  Efecto de la Terminación
                  <ol>
                    <li className={classes.liStyle}>
                      Una vez que todos sus Contratos de Crédito hayan sido
                      pagados o vendidos, usted podrá terminar su registro en
                      CFL enviándonos un correo electrónico a
                      info@capitalfundinglab.com y ya no podrá acceder a la
                      Plataforma a través de la Cuenta CFL cancelada o
                      terminada; una vez presentada la solicitud (pero en todo
                      caso dentro del Día Hábil siguiente a dicha presentación),
                      CFL le proporcionará un número de referencia para la
                      misma, así como la fecha y hora en que se recibió. Sin
                      embargo, nosotros y la Agencia de Cobranza continuaremos
                      manteniendo registros en la medida en que la Ley Aplicable
                      exija que lo hagamos.
                    </li>
                    <li className={classes.liStyle}>
                      Podremos cancelar su registro en CFL si usted proporciona
                      información falsa, incumple materialmente las condiciones
                      de estos Términos, fallece, se declara en bancarrota o
                      entra en un acuerdo voluntario o, en el caso de una
                      empresa, es puesto en administración, quiebra o
                      liquidación.
                    </li>
                    <li className={classes.liStyle}>
                      Podremos terminar o suspender su registro inmediatamente y
                      sin previo aviso si tenemos conocimiento o sospechamos
                      razonablemente que usted se encuentra involucrado en, o
                      puede ser víctima de cualquier actividad fraudulenta. Le
                      notificaremos por correo electrónico dicha terminación o
                      suspensión tan pronto como razonablemente sea posible, en
                      la medida en que nos sea permitido hacerlo por la Ley
                      Aplicable.
                    </li>
                    <li className={classes.liStyle}>
                      Cuando cancelemos su inscripción, le enviaremos en ese
                      momento a su Cuenta Bancaria , o por cheque a su último
                      domicilio conocido, cualesquier montos disponibles en su
                      Cuenta de Cliente, en el caso de que tenga saldo
                      disponible pendiente de ser invertido.
                    </li>
                    <li className={classes.liStyle}>
                      Después de la notificación de cancelación de su registro,
                      podremos proporcionarle acceso limitado a la Plataforma y
                      a su Cuenta CFL por un período de 7 días para descargar
                      información relacionada con sus Contratos de Crédito. Tras
                      la expiración de ese período de 7 días, su acceso a la
                      Plataforma y a su Cuenta CFL será cancelado y no tendremos
                      ninguna obligación de almacenar o proporcionarle acceso a
                      ninguna información en Cuenta CFL o relacionada con su
                      actividad en la Plataforma.
                    </li>
                    <li className={classes.liStyle}>
                      Asimismo, CFL podrá suspender su registro inmediatamente
                      en caso de que reciba una órden de alguna autoridad
                      administrativa o judicial, en este caso CFL valorará de
                      acuerdo a la legislación aplicable si corresponde o no
                      darle información respecto al motivo de la suspensión de
                      su registro.
                    </li>
                  </ol>
                </li>
              </ol>
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b> XV. Venta Secundaria de Partes del Crédito</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              <ol>
                <li className={classes.liStyle}>
                  La cancelación, terminación o retiro parcial de fondos (sujeto
                  a cualesquiera de los Requisitos del Producto) constituye una
                  instrucción del Inversionista a CFL para intentar vender
                  algunas o todas las Partes del Crédito en el que haya
                  invertido mediante la celebración de uno o más Convenios de
                  Cesión y Adhesión.
                </li>
                <li>
                  Las Partes del Crédito no pueden venderse, en las siguientes
                  circunstancias:
                  <ol>
                    <li>
                      Cuando queden tres o menos Pagos pendientes del Crédito; o
                    </li>
                    <li className={classes.liStyle}>
                      Cuando el Solicitante se encuentre en incumplimiento de
                      cualquiera de las obligaciones contraídas en el Contrato
                      de Crédito o se encuentre en mora.
                    </li>
                  </ol>
                </li>
                <li>
                  Cuando se vende una Parte del Crédito:
                  <ol>
                    <li className={classes.liStyle}>
                      El Inversionista vendedor (el “Vendedor”) cede y transmite
                      al Inversionista comprador (el “Comprador”) los derechos y
                      obligaciones que tiene respecto de la parte del Contrato
                      de Crédito (o sub-Crédito) y la parte correspondiente de
                      la garantía que se hubiere otorgado en el mismo y en el
                      cual haya decidido invertir.;
                    </li>
                    <li className={classes.liStyle}>
                      El Vendedor no tendrá más derechos y obligaciones bajo el
                      Contrato de Crédito en relación con la Parte del Crédito
                      vendida, pero la venta de la Parte del Crédito no afectará
                      ningún derecho u obligación que haya acumulado el Vendedor
                      hasta la fecha de la venta en relación con la Parte del
                      Crédito vendida, ni con ningún otro derecho que el
                      Vendedor pueda tener en virtud de las Partes del Crédito
                      no vendidas que el Vendedor retenga en el Contrato de
                      Crédito aplicable;
                    </li>
                    <li className={classes.liStyle}>
                      El Comprador se convertirá en inversionista de la parte
                      del Contrato de Crédito que hubiere adquirido y asumirá
                      todos los derechos y responsabilidades del Vendedor en la
                      medida en que estén relacionados con la Parte del Crédito
                      adquirida; y
                    </li>
                    <li className={classes.liStyle}>
                      Cada uno de los Vendedores, Compradores, y los
                      Solicitantes, acuerdan y autorizan a CFL a completar y
                      ejecutar o autenticar cualquier cesión o novación que CFL
                      pueda considerar necesaria o prudente en relación con la
                      transferencia de la parte del crédito que se va a vender
                    </li>
                  </ol>
                </li>
                <li className={classes.liStyle}>
                  CFL no garantiza el tiempo que le pueda tomar al Vendedor
                  vender una Parte del Crédito, ni que habrá algún Comprador
                  para alguna Parte del Crédito
                </li>
                <li>
                  Esta acción no supone ningún coste adicional al Comprador.
                </li>
              </ol>
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b> XVI. Límites de la Responsabilidad</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              <ol>
                <li className={classes.liStyle}>
                  Salvo que se indique expresamente lo contrario en estos
                  Términos y Condiciones, CFL sólo será responsable por pérdidas
                  o daños que surjan directamente de nuestro propio
                  incumplimiento, negligencia, dolo o mala fe.
                </li>
                <li className={classes.liStyle}>
                  No seremos responsables de ninguna pérdida o daño que surja de
                  o en relación con:
                  <ol>
                    <li className={classes.liStyle}>
                      Cualquier error o inexactitud en la información ingresada
                      por usted o por cualquier otro Cliente;
                    </li>
                    <li className={classes.liStyle}>
                      Cualquier negligencia, incumplimiento de contrato,
                      tergiversación o conducta dolosa en relación con los
                      Contratos de Crédito u otros usos de la Plataforma (que no
                      sean los nuestros).
                    </li>
                  </ol>
                </li>
                <li className={classes.liStyle}>
                  No seremos responsables de ninguna pérdida o daño indirecto,
                  especial o consecuente, incluidas las pérdidas o daños que
                  surjan de o en relación con la pérdida de información, la
                  pérdida de beneficios, los daños al fondo de comercio o la
                  interrupción de la actividad comercial, ni de ningún retraso o
                  incumplimiento de sus propias obligaciones en virtud de los
                  presentes Términos y Condiciones, debido a circunstancias que
                  se encuentren más allá de nuestro control razonable.
                </li>
                <li className={classes.liStyle}>
                  Nada en estos Términos y Condiciones excluirá o limitará la
                  responsabilidad de usted o de nosotros por muerte o lesiones
                  personales u omisiones negligentes o fraudulentas.
                </li>
                <li className={classes.liStyle}>
                  CFL estará completamente exento de la responsabilidad hacia el
                  Cliente de cualquier pérdida que el mismo haya o pueda sufrir
                  como resultado del servicio ofrecido por CFL, del uso de la
                  Página Web o de la Plataforma, incluyendo:
                  <ol>
                    <li className={classes.liStyle}>
                      Si el Cliente ha incumplido las disposiciones de estos
                      Términos y Condiciones y/o de los Contratos de Crédito,
                      Documentos del Crédito o Contrato de Comisión Mercantil
                      que tenga celebrados;
                    </li>
                    <li className={classes.liStyle}>
                      Como resultado de conductas ilícitas de terceros hasta el
                      momento en el que la Cuenta de CFL es bloqueada como
                      consecuencia de los procedimientos establecidos en los
                      presentes Término y Condiciones;
                    </li>
                    <li className={classes.liStyle}>
                      Debido a interrupciones en las conversaciones u otras
                      interrupciones y obstáculos que no dependan de CFL;
                    </li>
                    <li className={classes.liStyle}>
                      Si como consecuencia del Contrato, ha sido bloqueado o
                      detenido el acceso del Cliente a la Cuenta de CFL;
                    </li>
                    <li className={classes.liStyle}>
                      Si en virtud de una órden de alguna autoridad
                      administrativa o judicial, ha sido bloqueado o suspendido
                      el acceso del cliente a la Cuenta de CFL.
                    </li>
                    <li>
                      Infringir a través de conductas ilícitas el Contrato de
                      Crédito o de Comisión Mercantil.
                    </li>
                    <li className={classes.liStyle}>
                      Debido al no cumplimiento o retraso del cumplimiento de
                      los compromisos del Cliente derivados de los presentes
                      Términos y Condiciones y de los Contratos de Crédito que
                      correspondan.
                    </li>
                  </ol>
                </li>
              </ol>
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b> XVII. General</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              <ol>
                <li className={classes.liStyle}>
                  Exigibilidad: Si alguna parte de estos Términos y Condiciones
                  que no sea fundamental se considera ilegal o no exigible,
                  dicho hallazgo no afectará la validez o exigibilidad del resto
                  de los Términos y Condiciones.
                </li>
                <li className={classes.liStyle}>
                  Avisos: Usted acepta que toda la información, notificaciones y
                  documentos que tenemos derecho a enviar electrónicamente
                  pueden ser entregados a usted a través de la Plataforma, o
                  pueden ser enviados por correo a su última dirección de correo
                  conocida, incluyendo notificaciones de cesión relacionadas con
                  Contratos de Crédito. Es su responsabilidad revisar su Cuenta
                  CFL con regularidad. Es posible que le recordemos de vez en
                  cuando que revise su Cuenta CFL.
                </li>
                <li className={classes.liStyle}>
                  Registros concluyentes: Los registros mantenidos en la
                  Plataforma serán concluyentes respecto de los hechos y asuntos
                  que pretenden registrar, incluyendo la evidencia del título de
                  cada uno de los Contratos de Crédito.
                </li>
                <li className={classes.liStyle}>
                  Marcas Registradas: “CFL”, y diseño(s) relacionados, son
                  marcas registradas de CFL.
                </li>
                <li className={classes.liStyle}>
                  Renuncia: Si no aplicamos o ejercemos estrictamente en
                  cualquier momento nuestros derechos bajo los presentes
                  Términos, eso no nos impedirá a hacerlo más tarde.
                </li>
                <li className={classes.liStyle}>
                  Impuestos: Si se le impone algún impuesto como resultado de la
                  celebración de estos Términos o de cualquier Contrato de
                  Crédito, usted mismo debe rendir cuentas de los mismos.
                </li>
                <li className={classes.liStyle}>
                  Derecho Aplicable y Jurisdicción: Cualquier disputa o
                  reclamación contractual o no contractual que surja de o en
                  relación con la Plataforma o su objeto o formación se regirá
                  por las leyes mexicanas y dichas disputas o reclamaciones
                  estarán sujetas a la jurisdicción de los tribunales de la
                  Ciudad de México; al aceptar estos Términos, el Cliente y CFL
                  se someten expresamente a la jurisdicción de los tribunales de
                  la Ciudad de México y renuncian expresamente al fuero que
                  pudiere corresponderles por razón de sus domicilios presentes
                  o futuros o de cualquier otra manera.
                </li>
              </ol>
            </Typography>
            <br /> <br />
            <Typography variant="body1" gutterBottom inline align="justify">
              Los servicios ofrecidos por CFL, así como el Sitio Web y la
              Plataforma deberán ser usados única y exclusivamente de acuerdo
              con lo establecido y en cumplimiento de estos Términos y
              Condiciones.Utilizamos cookies y tecnologías similares para
              proporcionar el Servicio. Mediante el uso de la Plataforma o
              Página Web de CFL está aceptando los términos de nuestra política
              de privacidad y cookies
            </Typography>
            <br /> <br />
            <Typography variant="h3" gutterBottom inline>
              <b> XVIII. Términos Definidos</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Los presentes términos tendrán el significado que se les atribuye
              a continuación:
              <br /> <br />
              “Agencia de Cobranza” significa cualquier agencia de cobranza
              designada por CFL o cualquier agente
              <br /> <br />
              “Calificación Crediticia” significa la calificación proporcionada
              a nosotros por cualquier sociedad de información crediticia que
              usemos de tiempo en tiempo en relación con un Cliente de
              conformidad con la Plataforma.
              <br /> <br />
              “Carta de Reinversión” significa una carta firmada por el Cliente
              conforme al formato disponible en la Plataforma para dichos
              efectos, en virtud de la cual otorga su consentimiento para
              habilitar la función de Reinversión y autoriza a CFL para estos
              efectos.
              <br /> <br />
              “Claves de Acceso” significa el correo electrónico, contraseña y
              respuestas a las preguntas de seguridad, que usted escoge cuando
              se registra con nosotros como Cliente en la Plataforma, y con las
              cuales tendrá acceso a su Cuenta CFL, según sean modificadas de
              tiempo en tiempo por usted.
              <br /> <br />
              “Contrato de Comisión Mercantil” significa el contrato celebrado
              entre Emprendimientos Maussan y González, Sociedad Anónima
              Promotora de Inversión de Capital Variable, Institución de
              Financiamiento Colectivo, (CFL) con un Inversionista, desde el
              momento en que es aceptada su inversión en la plataforma y a
              través del cual se faculta a CFL, entre otros actos, a distribuir
              su inversión en los diversos créditos de los Solicitantes, a
              representarlo en el Contrato de Crédito que celebre con los mismos
              y a realizar las gestiones de cobro en caso de incumplimiento por
              parte de uno o más Solicitantes
              <br /> <br />
              “Contrato de Crédito” significa el contrato celebrado entre uno o
              más Inversionistas, representados en ese acto por Emprendimientos
              Maussan y González, Sociedad Anónima Promotora de Inversión de
              Capital Variable y un Solicitante, cuando una Solicitud de Crédito
              es empatada con una Oferta de Mercado de uno o más
              Inversionistas., .
              <br /> <br />
              “Convenio de Cesión y Adhesión” significa el convenio de cesión y
              adhesión respecto de un Contrato de Crédito que celebre un
              Inversionista del mismo, como cedente, con otra Persona que se
              encuentre registrada como Inversionista en la Plataforma, como
              cesionario, y en virtud del cual el Inversionista cedente cede y
              transmite al Inversionista cesionario sus derechos respecto de la
              Parte del Crédito de la que sea titular en el Contrato de Crédito
              de que se trate, y el Inversionista cedente acepte dicha Parte del
              Crédito y se adhiera al Contrato de Crédito como Inversionista,
              conforme al procedimiento disponible para tales efectos en la
              Plataforma.
              <br /> <br />
              “Criterios de Elegibilidad” significa las condiciones establecidas
              en la Sección V que tendrá que satisfacer para ser registrado como
              Cliente
              <br /> <br />
              “Cuenta Bancaria del Solicitante” significa la cuenta bancaria
              abierta y mantenida por un Solicitante en una Entidad Financiera,
              y designada como tal por dicho Solicitante en su Solicitud de
              Crédito o de otra forma a través de la Plataforma.
              <br /> <br />
              “Cuenta Bancaria del Inversionista”significa la cuenta bancaria
              abierta y mantenida por un Inversionista en una Entidad
              Financiera, y designada como tal por dicho Inversionista en su
              Solicitud de Inversión o de otra forma a través de la Plataforma.
              <br /> <br />
              “Cuenta Concentradora de Clientes” aquella cuenta bancaria que CFL
              mantenga en su nombre con una o más Entidades Financieras, para el
              depósito y concentración de los recursos de los Clientes que le
              sean entregados para la realización de operaciones, así como
              aquellos que provengan o se deriven de la realización de
              operaciones y/o la inversión de dichos fondos.
              <br /> <br />
              “Cuenta de Cliente” significa aquella subcuenta dentro de una
              Cuenta Concentradora de Clientes, que CFL mantenga a nombre de sus
              Clientes y en la que, entre otros, se realicen los registros de
              (i) abonos correspondientes a lo siguiente: (a) las cantidades en
              moneda nacional que cada Cliente proporcione a CFL para la
              realización de operaciones, y (b) transferencias de cantidades en
              moneda nacional que provengan de los pagos efectuados por los
              financiamientos otorgados a los Clientes o de operaciones no
              perfeccionadas, así como (ii) cargos que correspondan por (a) la
              inversión de dichos fondos, (b) la disposición de dichos fondos, o
              (c) su transferencia
              <br /> <br />
              “Día Hábil” significa cualquier día excepto sábado, domingos,
              cualquier día considerado como día festivo en México y cualquier
              otro día en que las oficinas principales de los bancos comerciales
              ubicados en México estén autorizadas o requeridas por ley para
              permanecer cerradas.
              <br /> <br />
              “Documentos del Crédito” significa respecto de cada Crédito,
              conjuntamente, el Contrato de Crédito correspondiente, la Garantía
              respectiva y cualquier otro contrato, documento o instrumento
              relacionado con el presente Contrato y/o la Garantía, en cada
              caso, conjuntamente con cualesquier extensiones, renovaciones,
              sustituciones, modificaciones o reexpresiones de los mismos.
              <br /> <br />
              “Ley Aplicable" significa cualquier ley, regulación, regla,
              circular, orden o decreto, u otras disposiciones, aplicables a
              CFL, la operación de la Plataforma o los Contratos de Crédito.
              <br /> <br />
              “Oferta de Mercado” significa la oferta de crédito de
              Inversionistas a Solicitantes.
              <br /> <br />
              “Pago” significa cada amortización de principal, intereses y demás
              accesorios y cantidades debidas y pagaderas por el Solicitante en
              cualquier Fecha de Pago conforme a un Contrato de Crédito
              <br /> <br />
              “Parte del Crédito” significa, cuando más de un Inversionista
              otorga un Crédito a un Solicitante, la cantidad proporcionada por
              cada Inversionista.
              <br /> <br />
              “Plataforma” significa el mercado de préstamos entre pares (peer
              to peer) que CFL opera con el propósito de empatar Ofertas de
              Mercado y Solicitudes de Crédito, e incluye cualquier versión
              móvil o cualquier otra aplicación u otros medios de acceso a ese
              mercado, que CFL opere de tiempo en tiempo
              <br /> <br />
              “Propuesta de Crédito Personal” significa un documento
              proporcionado a un posible Solicitante que presenta (entre otros
              elementos), el importe del crédito, el plazo, el Costo Anual
              Total, las comisiones a ser cobradas, la Garantía requerida, el
              importe de los intereses y el importe total a pagar (en el caso de
              que la Solicitud de Crédito del posible Solicitante coincida con
              una Oferta de Mercado, este documento estará en la forma
              establecida por la Ley Aplicable).
              <br /> <br />
              “Requisitos del Producto” significa los términos, requisitos y
              políticas de préstamos, establecidas por los Inversionistas y CFL
              de tiempo en tiempo, que aplican a los Créditos a ser realizados
              en la Plataforma por dicho Inversionista. “Garantía” significa
              cualquier garantía proporcionada por el Solicitante para asegurar
              el cumplimiento de sus obligaciones con respecto a un Crédito o a
              una Parte del Crédito a favor del Inversionista
              <br /> <br />
              “Reinversión” significa nuestra función para inversión automática
              de recursos de los Inversionistas que provengan de los pagos
              efectuados bajo los financiamientos otorgados por los mismos o de
              operaciones no perfeccionadas, con base en los Requisitos del
              Producto aplicables, usando cualquier cantidad depositada por un
              Inversionista en su cuenta de CFL y conforme a lo dispuesto en el
              Artículo 54, Fracción I de las Disposiciones de carácter general
              aplicables a las Instituciones de Tecnología Financiera.
              <br /> <br />
              “Sitio Web” significa el sitio web de CFL,
              https://www.capitalfundinglab.com/."
              <br /> <br />
              Emprendimientos Maussan y González, S.A.P.I. de C.V., Institución
              de Financiamiento Colectivo, es Titular de los Derechos de Autor
              derivados del contenido del presente documento. La información
              incluida en este documento está protegida por la Ley Federal del
              Derecho de Autor y no se otorga una licencia de esta protección a
              sus usuarios. La reproducción, alteración, distribución o
              redistribución de cualquier información incluida en el presente
              documento por cualquier medio constituirá una violación a la Ley
              Federal del Derecho de Autor, salvo que medie el consentimiento
              previo y por escrito de Emprendimientos Maussan y González,
              S.A.P.I. de C.V.Institución de Financiamiento Colectivo
            </Typography>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}
